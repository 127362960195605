<template>
  <div v-if="data && data.calendars.nodes.length > 0">
    <div class="Calendars Component is-light">
      <div class="Page__container" v-if="data">

        <div class="Page__container is-small">
          <Section v-if="portrait" :text="ui.broadcasts" />

          <div class="Calendars__top">
            <SplitText type="line" v-if="ui.calendar || ui.past_broadcasts">
              <h2 class="h2">{{ type == 'new' ? ui.calendar : ui.past_broadcasts }}</h2>
            </SplitText>
            <div v-if="$route.meta.name == 'creations' || $route.meta.name == 'calendar'" class="Calendars__orderby button2" :class="'is-' + orderby" @click="orderby = orderby == 'ASC' ? 'DESC' : 'ASC'">
              {{ ui.order_by }} <Icon type="arrowDown" />
            </div>
          </div>
        </div>

        <Section v-if="landscape" :text="ui.broadcasts" />

        <div class="Page__container is-small">
          <div class="Calendars__blocks" :class="'is-' + type">
            <Viewport v-for="(calendar, index) in data.calendars.nodes" :key="index">
              <a
                @mouseenter="showCursor('buy', 'black')"
                @mouseleave="hideCursor"
                :href="calendar.acf.url"
                target="_blank"
                class="Calendars__block Viewport__row">
                <div class="Calendars__block-top" v-if="calendar.theaters.nodes.length > 0">
                  <span :class="{ h5: type == 'new', subtitle1: type == 'old', 'Calendars__city-old': type == 'old' }">
                    <template v-if="$route.meta.name == 'creations' || $route.meta.name == 'calendar' && calendar.acf.creation">
                      {{ calendar.acf.creation.acf.content.title }}
                    </template>
                    <template v-else>
                      {{ calendar.theaters.nodes[0].acf.city }}
                    </template>
                  </span>
                  <span v-if="landscape" class="Calendars__sep" :class="{ h5: type == 'new', subtitle1: type == 'old' }">|</span>
                  <span
                    :class="[{ body1: type == 'new', body2: type == 'old' }, 'is-' + type]"
                    class="Calendars__room"
                    v-html="calendar.theaters.nodes[0].acf.title" />
                  <div class="Calendars__city button2" v-if="$route.meta.name == 'creations' || $route.meta.name == 'calendar'">{{ calendar.theaters.nodes[0].acf.city }}</div>
                </div>
                <div class="Calendars__date" :class="type == 'new' ? 'subtitle1' : 'caption'">
                  {{ calendar.acf._start }} - {{ calendar.acf._end }}
                </div>
                <CirclePlus v-if="portrait && type == 'new'" />
              </a>
            </Viewport>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon'
import { computed, watch, ref } from '@vue/composition-api'
import { useQuery } from 'villus'
import { format, parseISO } from 'date-fns'
import CirclePlus from '@/components/circle-plus/CirclePlus'

export default {
  name: 'Calendars',
  components: { Icon, CirclePlus },
  props: {
    type: { default: 'new' }
  },
  setup(props, { root }) {
    const orderby = ref(props.type == 'new' ? 'ASC' : 'DESC')
    const today = format(new Date(), 'yyyyMMdd')
    const compare = props.type == 'new' ? 'GREATER_THAN_OR_EQUAL_TO' : 'LESS_THAN'

    const id = root.$route.params.id
    const creationMetaQuery = root.$route.meta.name == 'creation' ? `{compare: EQUAL_TO, key: "creation", type: NUMERIC, value: "${root.$route.params.id}"}` : ''
    const { data, error, done } = useQuery({
      query: computed(() => {
        const q = `{
          site(handle: "${root.lang}")
          calendars(first: 2000 where: {
            orderby: {field: CALENDAR_END, order: ${orderby.value}}
            metaQuery: {
              relation: AND
              metaArray: [
                {compare: ${compare}, key: "end", type: DATE, value: "${today}"} ${creationMetaQuery}
              ]
            }}) {
						nodes {
							acf {
                creation { ... on Creation { acf { content { ${root.af('title')} } } } }
								end
								start
                ${root.af('url')}
							}
							theaters { nodes { acf { city ${root.af('title')} } } }
						}
					}
        }`
        return q
      })
    })

    watch(data, () => {
      if(!data.value) return
      _.each(data.value.calendars.nodes, (node, index) => {
        root.$set(node.acf, '_start', format(parseISO(node.acf.start), 'dd.MM (yyyy)'))
        root.$set(node.acf, '_end', format(parseISO(node.acf.end), 'dd.MM (yyyy)'))
      })
    })

    return { data, orderby }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Calendars
  display flex

  .CirclePlus
    position absolute
    right 0
    bottom pxp(20)

  &__city-old
    +portrait()
      margin pxp(5) 0

  &__room
    +portrait()
      width 100%
      display block
      order 1
      margin-bottom pxp(5)
      &.is-new
        margin-top pxp(20)

  &__city
   mq(margin-top 3 5)

  &__sep
    margin 0 pxl(10)

  &__top
    margin-bottom pxl(70)
    display flex
    justify-content space-between
    align-items center
    +portrait()
      flex-direction column
      align-items flex-start
      margin-top pxp(40)
      margin-bottom pxp(50)

  &__orderby
    display flex
    align-items center
    cursor pointer
    +portrait()
      align-self flex-end
      margin-top pxp(34)
    &.is-ASC
      svg
        transform rotate(180deg)
    .Icon
      transition transform .5s
      display block
      mq(margin-left 15 15)

  &__blocks
    &.is-old
      display flex
      flex-wrap wrap
      .Calendars__sep
        display none
      +portrait()
        scrollX()
        margin-left: -(gutter.portrait)
        max-height pxp(400)
        flex-direction column
      span
        display block
      .Calendars__date
        margin-bottom pxl(10)
      .Calendars__block
        width pxl(185)
        margin-bottom pxl(25)
        border none
        padding 0
        flex-direction column
        pointer-events none
        padding-right pxl(15)
        justify-content flex-start
        box-sizing border-box
        +portrait()
          width pxp(226)
          padding-right pxp(10)
          margin-bottom pxp(25)
        div
          &:nth-child(2)
            order -1

  &__block
    border-top-width 1px
    border-style solid
    padding pxl(30) 0
    display flex
    justify-content space-between
    &-top
      +portrait()
        display flex
        flex-direction column
    +portrait()
      padding pxp(27) 0
      flex-direction column
    &:last-child
      border-bottom-width 1px

</style>
