<template>
  <div class="CoverGradient" :class="direction">
    <Cover v-if="cover" :caption="true" :appear="appear" :cover="cover" />
    <div class="CoverGradient__gradient" />
  </div>
</template>

<script>
import Cover from '@/components/cover/Cover'

export default {
  name: 'intro',
  components: { Cover },
  props: {
    cover: { default: null },
    direction: { default: 'to-bottom' },
    appear: { default: true }
  },
  setup() {}
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.CoverGradient
  position absolute
  top 0
  left 0
  height 100%
  width 100%
  overflow hidden
  &.to-top
    .CoverGradient__gradient
      bottom auto
      top 0
      background linear-gradient(to top, rgba(colors.grey.dark, 0), rgba(colors.grey.dark, 1))

  &__gradient
    position absolute
    bottom 0
    left 0
    right 0
    height pxl(300)
    background linear-gradient(to bottom, rgba(colors.grey.dark, 0), rgba(colors.grey.dark, 1))
    pointer-events none
    +portrait()
      height pxp(200)

  &__container
  &__cover
    position absolute
    height 100%
    width 100%

</style>

