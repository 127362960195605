<template>
  <Viewport>
  <div class="Footer is-dark Viewport__scalefade">
    <div class="Footer__el" ref="root">

      <div class="Page__container" ref="el">
        <template v-if="data">
          <div class="Footer__top">
            <div class="Page__container is-small">
              <div class="Footer__left">

                <div>
                  <div class="Footer__label h3">{{ data.general.acf.newsletter.label }}</div>
                  <Btn :data="data.general.acf.newsletter.btn" />
                  <!--transition name="Footer__newsletter" mode="out-in">
                    <div class="Footer__newsletter h3":key="subscribed">
                      {{ data.general.acf.newsletter[subscribed ? 'thank' : 'title'] }}
                    </div>
                  </transition-->
                </div>


                <!--transition name="Footer__form">
                  <div class="Footer__form" v-if="!subscribed" :class="{ 'is-error': model.error }">
                    <InputText
                      :error="model.error"
                      @update="model.value = $event"
                      :text="data.general.acf.newsletter.input"
                    />
                    <div class="Footer__submit" @click="send"><Icon type="arrow" /></div>
                    <div class="Footer__error caption">{{ data.general.acf.newsletter.error }}</div>
                  </div>
                </transition-->

              </div>
              <div class="Footer__right">
                <div class="Footer__address">
                  <div class="Footer__label caption">{{ data.general.acf.address.label }}</div>
                  <div class="important">{{ data.general.acf.address.address }}</div>
                </div>
                <div class="Footer__contact">
                  <div class="Footer__label caption">{{ data.general.acf.contact.label }}</div>
                  <a :href="'tel:' + data.general.acf.contact.email" class="important">{{ data.general.acf.contact.phone }}</a>
                  <a :href="'mailto:' + data.general.acf.contact.email" class="important">{{ data.general.acf.contact.email }}</a>
                </div>
                <Socials />
              </div>
            </div>
          </div>
          <div class="Footer__bottom">
            <div class="Page__container is-small">
              <div class="Footer__left Footer__copyright caption">
                {{ data.general.acf.footer.copyright }}
              </div>
              <div class="Footer__right">
                <div class="Footer__links">
                  <Btn :appear="false" type="caption" :icon="false" :data="btn.btn" :key="index" v-for="(btn, index) in data.general.acf.footer.links" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

    </div>
  </div>
  </Viewport>
</template>

<script>
import { computed, onUnmounted, watch, ref, onMounted } from '@vue/composition-api'
import { useQuery } from 'villus'
import Socials from '@/components/socials/Socials'
import Icon from '@/components/icon/Icon'
import Btn from '@/components/btn/Btn'
import BtnQuery from '@/components/btn/BtnQuery'
import InputText from '@/components/input-text/InputText'
// import { gsap } from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)


export default {
  name: 'Footer',
  components: { Socials, Icon, Btn, InputText },
  setup(props, { root, refs }) {
    const { data, error, done } = useQuery({
      query: computed(() => `{
        general {
          acf {
            contact { ${root.af('label')} phone email }
            address { ${root.af('label')} ${root.af('address')} }
            footer { ${root.af('copyright')} links { ${BtnQuery(root)} } }
            newsletter { 
              ${BtnQuery(root)} ${root.af('label')} 
            }
          }
        }
      }`)
    })

    // newsletter
    // ${root.af('title')} ${root.af('input')} ${root.af('thank')} ${root.af('error')} 

    // -------------------------------------

    const subscribed = computed(() => root.$store.getters['app/subscribed'])
    const model = ref({ value: null, error: false, required: true })
    const send = async () => {
      model.value.error = !/\S+@\S+\.\S+/.test(model.value.value)

      if(model.value.error) return

      const res = await fetch('/api/newsletter', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(model.value)
      })
      const json = await res.json()

      root.$store.commit('app/SET_SUBSCRIBE')
    }

    // -------------------------------------

    // let st = null
    // const tl = gsap.timeline({ paused: true })

    // onUnmounted(() => {
    //   st.kill()
    //   tl.kill()
    // })

    // onMounted(() => {
    //   watch(data, () => {
    //     if(!data.value) return
    //     if(root.portrait) return

    //     root.$nextTick(() => {
    //       gsap.set(refs.el, { yPercent: -100 })
    //       tl.to(refs.el, { yPercent: 0, ease: 'none' })

    //       st = ScrollTrigger.create({
    //         scrub: true,
    //         trigger: refs.root,
    //         start: 'top bottom',
    //         end: 'bottom bottom',
    //         animation: tl
    //       })
    //     })
    //   })
    // })

    // -------------------------------------

    return { data, model, send, subscribed }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Footer
  mq(
    padding-top 100 0
  )

  &__el
    overflow hidden

  &__address
    white-space pre-line

  &__error
    position absolute
    left 0
    bottom pxl(-20)
    color: colors.red
    transition opacity .5s
    opacity 0
    +portrait()
      bottom pxp(-20)

  &__newsletter
    transition opacity .5s, transform .5s
    &-enter
    &-leave-active
      opacity 0
      transform scale(.9)

  .Page__container.is-small
    display flex
    justify-content space-between
    +portrait()
      flex-direction column

  .Socials
    mq(margin-top 25 25)

  &__label
    mq(margin-bottom 10 15)

  &__contact
    mq(margin-top 40 40)
    a
      display block

  &__top
    border-top 1px solid rgba(colors.white, .3)
    border-bottom 1px solid rgba(colors.white, .3)
    padding pxl(130) 0
    +portrait()
      padding pxp(50) 0

  &__left
    display flex
    flex-direction column
    justify-content space-between
    mq(width 515 100%)

  &__right
    mq(width 295 100%)

  &__bottom
    padding pxl(50) 0
    +portrait()
      padding pxp(25) 0

  &__form
    border-bottom-width 1px
    border-style solid
    position relative
    display flex
    transition opacity .5s, border .5s
    &.is-error
      border-color: colors.red
      .Footer__error
        opacity 1
      svg
        transition fill .5s
        fill: colors.red
    &-leave-active
      opacity 0
    +portrait()
      margin-top pxp(30)
      margin-bottom pxp(70)

  &__submit
    align-self flex-end
    padding pxl(20) 0 pxl(20)
    cursor pointer
    transition transform .5s
    +landscape()
      &:hover
        transform translateX(pxl(10))
    +portrait()
      align-self center
      padding pxp(10) 0 pxp(5)

  &__links
    display flex
    justify-content space-between
    +portrait()
      flex-direction column
      a
        margin-bottom pxp(15)
        &:last-child
          margin-bottom 0


  &__copyright
    +portrait()
      margin-top pxp(20)
      margin-bottom pxp(15)

</style>
