<template>
  <div class="Quote Component is-dark">
    <div class="Quote__content">
      <h2 class="Quote__text h2">
        «{{data.text}}»
      </h2>
      <div class="Quote__author button2">{{ data.author }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Quote',
  props: {
    data: { default: null }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Quote
  padding-top: gutter.component.landscape
  padding-bottom: gutter.component.landscape
  +portrait()
    padding-top: gutter.component.portrait
    padding-bottom: gutter.component.portrait

  &__content
    margin 0 auto
    mq(width 845 336)
    display flex
    flex-direction column
    text-align center

  // &__text
  //   text-align center

  &__author
    // align-self flex-end
    mq(
      margin-top 40 20
    )

</style>
