<template>
  <div class="BtnCircle button2"  :class="['is-' + color]">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'BtnCircle',
  props: {
    text: { default: '' },
    color: { default: 'white' }
  },
  setup(props, { root, refs }) {}
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.BtnCircle
  display flex
  justify-content center
  align-items center
  position relative
  mq(
    width 132 132,
    height 132 132
  )
  &.is-white
    color white
  &.is-black
    color black
  &:before
    border: 1px solid colors.red
    content ''
    position absolute
    width 100%
    height 100%
    border-radius 100%

</style>

