<template>
  <div class="ProfilRepeater Component">
    <div class="Page__container is-small">

      <SplitText type="line">
        <h2 class="h2" v-html="data.label" />
      </SplitText>

      <div class="ProfilRepeater__blocks">
        <div v-for="profil in data.list" class="ProfilRepeater__block">
          <div class="ProfilRepeater__cover">
            <Cover :caption="true" :cover="profil.cover" />
          </div>
          <div class="ProfilRepeater__name h5" v-html="profil.name" />
          <div class="ProfilRepeater__title body1" v-html="profil.title" />
          <div class="ProfilRepeater__icons">

            <a target="_blank" :href="'mailto:' + profil.email" class="ProfilRepeater__icon is-email">
              <Icon type="email" />
            </a>
            <a target="_blank" :href="profil.linkedin" v-if="profil.linkedin && profil.linkedin != ''" class="ProfilRepeater__icon is-linkedin">
              <Icon type="linkedin" />
            </a>
            <a target="_blank" :href="profil.instagram" v-if="profil.instagram && profil.instagram != ''" class="ProfilRepeater__icon is-instagram">
              <Icon type="instagram" />
            </a>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Cover from '@/components/cover/Cover'
import Icon from '@/components/icon/Icon'

export default {
  name: 'ProfilRepeater',
  components: { Cover, Icon },
  props: {
    data: { default: null }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.ProfilRepeater

  &__blocks
    display flex
    flex-wrap wrap
    mq(margin-top 80 40)
    +portrait()
      scrollX()
      height pxp(550)
      flex-wrap nowrap
      margin-left: -(gutter.portrait)

  &__block
    flex-grow 0
    flex-shrink 0
    mq(
      margin-bottom 36 36,
      margin-right 31 20,
      width 334 85%
    )
    &:nth-child(3n)
      +landscape()
        margin-right 0

  &__cover
    mq(height 500 400)
    position relative

  &__name
    mq(margin-top 25 25)

  &__icons
    display flex
    align-items center
    mq(
      height 50 50,
      margin-left -10 -10
    )

  &__icon
    padding pxl(0) pxl(10)
    +portrait()
      padding pxp(0) pxp(10)
    &.is-email
      svg
        mq(
          width 16 16,
          height 12 12
        )
    &.is-linkedin
      svg
        mq(
          width 12 12,
          height 12 12
        )
    &.is-instagram
      svg
        mq(
          width 12 12,
          height 12 12
        )

</style>
