var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ExMachina"},[_c('Intro'),(_vm.data)?[_c('div',{staticClass:"Page__container is-small Component"},[_c('SplitText',{attrs:{"type":"line"}},[_c('h2',{staticClass:"h2-alt",domProps:{"innerHTML":_vm._s(_vm.data.page.acf.intro.intro)}})])],1),_c('div',{staticClass:"Page__container ExMachina__intro"},[_c('BlockFlexible',{attrs:{"data":{
        text: _vm.data.page.acf.intro.text,
        textColumn: true,
        sections: _vm.data.page.acf.intro.label
      }}})],1),_c('div',{staticClass:"ExMachina__cover"},[_c('CoverGradient',{attrs:{"direction":"to-top","cover":_vm.data.page.acf.content.cover}})],1),_c('div',{staticClass:"Component is-light"},[_c('BlockFlexible',{attrs:{"data":{
        title: _vm.data.page.acf.content.title,
        hasTitle: true,
        text: _vm.data.page.acf.content.text,
        sections: _vm.data.page.acf.content.label,
        isWysiwyg: false,
        textColumn: true
      }}})],1),_c('div',{staticClass:"Component is-light"},[(_vm.data.page.acf.publications)?_c('Publications',{attrs:{"publications":_vm.data.page.acf.publications}}):_vm._e()],1),(_vm.data.page.acf.gallery)?_c('Gallery',{attrs:{"data":_vm.data.page.acf.gallery}}):_vm._e(),_c('div',{staticClass:"ExMachina__studio Component is-light"},[_c('BlockFlexible',{attrs:{"data":{
        hasTitle: true,
        titleClass: 'h2',
        title: _vm.data.page.acf.studio.title,
        text: _vm.data.page.acf.studio.text,
        btn: _vm.data.page.acf.studio.btn,
        textColumn: true
      }}}),_c('div',{staticClass:"Page__container is-small ExMachina__studio-cover"},[_c('Cover',{attrs:{"caption":true,"cover":_vm.data.page.acf.studio.cover}})],1)],1),_c('div',{staticClass:"Component is-light"},[_c('Cta',{attrs:{"data":_vm.data.page.acf.cta}})],1),_c('Partners',{attrs:{"data":_vm.data.page.acf.partners}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }