<template>
  <div class="Share">
    <div class="Share__txt caption">
      {{ ui.share }}
    </div>
    <div class="Share__line" />
    <div class="Share__icons">

      <a @click="share('facebook')" class="Share__btn">
        <svg viewBox="0 0 20.01 20.01">
          <path d="M12.65,10H10.84v6.74H8.16V10H6.89V7.62H8.16V6.08a2.59,2.59,0,0,1,2.71-2.83h2V5.57H11.41a.57.57,0,0,0-.57.65V7.63h2.05Z"/>
        </svg>
      </a>

      <a @click="share('twitter')" class="Share__btn">
        <svg viewBox="0 0 20.01 20.01">
          <path d="M16.39,5.8a5.07,5.07,0,0,1-1.53.44A2.77,2.77,0,0,0,16,4.71a5.59,5.59,0,0,1-1.7.67,2.59,2.59,0,0,0-1.94-.88A2.73,2.73,0,0,0,9.72,7.28a2.59,2.59,0,0,0,.07.63A7.46,7.46,0,0,1,4.29,5a2.86,2.86,0,0,0-.36,1.4A2.8,2.8,0,0,0,5.12,8.72a2.62,2.62,0,0,1-1.21-.35v0a2.76,2.76,0,0,0,2.14,2.72,2.69,2.69,0,0,1-.7.1,2.84,2.84,0,0,1-.51-.05,2.69,2.69,0,0,0,2.5,1.92A5.23,5.23,0,0,1,4,14.29a4.44,4.44,0,0,1-.63,0A7.31,7.31,0,0,0,7.48,15.5a7.7,7.7,0,0,0,7.58-7.9V7.24A5.54,5.54,0,0,0,16.39,5.8"/>
        </svg>
      </a>

      <a class="Share__btn" :href="mailto" target="_blank">
        <svg viewBox="0 0 20.01 20.01">
          <path d="M4.39,4.5a1.49,1.49,0,0,0-1,.39l6.5,4.5,6.49-4.5a1.46,1.46,0,0,0-1-.39ZM16.86,5.77l-7,4.84-7-4.83a1.5,1.5,0,0,0,0,.22v8a1.5,1.5,0,0,0,1.5,1.5h11a1.5,1.5,0,0,0,1.5-1.5V6A.91.91,0,0,0,16.86,5.77Z"/>
        </svg>
      </a>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Share',
  setup(props, { root }) {
    const currentUrl = window.location.href
    const mailto = `mailto:?body=${currentUrl}`
    const share = type => {
      const w = 550
      const h = 450
      const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left
      const dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top
      const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
      const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height
      const left = ((width / 2) - (w / 2)) + dualScreenLeft
      const top = ((height / 2) - (h / 2)) + dualScreenTop
      const urls = {
        'facebook': 'https://www.facebook.com/sharer/sharer.php?u=',
        'twitter': 'https://twitter.com/share?url=',
      }
		  const url = urls[type] + encodeURI(currentUrl)
      const newWindow = window.open(url, '', 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)
      if(newWindow && newWindow.focus)
        newWindow.focus()
    }
    return { share, mailto }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Share
  display flex
  align-items center
  +landscape()
    right pxl(69)
    top 50%
    transform translateY(-50%)
    z-index 9
    position fixed
    flex-direction column
    mix-blend-mode difference
  +portrait()
    margin pxp(20) 0

  &__txt
    +landscape()
      writing-mode vertical-rl
      transform rotate(-180deg)

  &__line
    +portrait()
      background: colors.black
      margin 0 pxp(30)
      height 1px
      width pxp(50)
    +landscape()
      margin pxl(40) 0
      background: colors.white
      width 1px
      height pxl(80)

  &__icons
    +portrait()
      display flex
    +landscape()
      width pxl(20)

  &__btn
    display block
    transition transform .5s
    cursor pointer
    mq(
      width 20 20,
      height 20 20
    )
    +portrait()
      margin-right pxp(20)
    +landscape()
      margin-bottom pxl(20)
      &:hover
        transform scale(1.2)
    &:last-child
      margin-bottom 0
      margin-right 0

</style>
