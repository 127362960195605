<template>
  <div class="Intro">
    <template v-if="data">
      <CoverGradient :appear="false" :cover="data.page.acf.intro.cover" />
      <SplitText from="center">
        <Parallax :y="100">
          <h1 class="highlight">{{ data.page.acf.intro.title }}</h1>
        </Parallax>
      </SplitText>
    </template>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useQuery } from 'villus'
import CoverGradient from '@/components/cover-gradient/CoverGradient'

export default {
  name: 'intro',
  components: { CoverGradient },
  setup(props, { root }) {
    const acf_key = root.$route.meta.name == 'home' ? 'acf' : 'acf_' + root.$route.meta.name

    const { data, error, done } = useQuery({
      query: computed(() => `{
        page(id: ${root.$route.meta.id}, idType: DATABASE_ID) { acf: ${acf_key} { intro { ${root.af('title')} cover { sourceUrl(size: LARGE) } } } }
      }`)
    })
    return { data }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Intro
  height 100vh
  position relative
  display flex
  justify-content center
  align-items center
  box-sizing border-box
  padding: gutter.landscape
  +portrait()
    padding: gutter.portrait

  .Cover__caption
    display none

  .CoverGradient
    position absolute

  .highlight
    position relative
    pointer-events none

</style>
