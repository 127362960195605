<template>
  <div id="App" class="is-dark" @wheel="onWheel" :class="{ 'is-ready': done }">
    <Seo />
    <Ui />
    <Navigation v-show="nav" />
    <Top />
    <CursorComp />
    <Search v-if="search" />
    <transition @enter="enter" @leave="leave">
      <div tabindex="1"  :key="pageKey" >
        <div class="Page">
          <router-view />
          <ScrollCta v-if="$route.meta.name != 'article'" />
          <Share v-if="$route.meta.name == 'article' && landscape" />
        </div>
        <Footer />
      </div>
    </transition>
    <div ref="mask" id="App__mask" />
  </div>
</template>

<script>
import Top from '@/components/top/Top'
import { useScroll, onWheel } from '@/components/scroll/Scroll'
import { computed, ref, watch } from '@vue/composition-api'
import { useClient, batcher, useQuery } from 'villus'
import Footer from '@/components/footer/Footer'
import Seo from '@/components/seo/Seo'
import Ui from '@/components/ui/Ui'
import ScrollCta from '@/components/scroll-cta/ScrollCta'
import Navigation from '@/components/navigation/Navigation'
import Search from '@/components/search/Search'
import { useTransition } from './AppTransition'
import CursorComp from '@/components/cursor/Cursor'
import Share from '@/components/share/Share'

export default {
  name: 'App',
  components: { Top, Footer, Seo, Ui, ScrollCta, Navigation, Search, CursorComp, Share },
  setup(props, { root, refs }) {

    useClient({
      url: '/cms/graphql',
      fetch: batcher()
    })

    const { data, error, done } = useQuery({ query: computed(() => `{ allSettings { generalSettingsUrl } }`) })

    // ----------------------

    const pageKey = computed(() => {
      let key = root.$route.meta.name

      if(root.$route.meta.name == 'creation')
        key += root.$route.params.id

      return key + root.landscape + root.lang
    })

    // ----------------------

    const nav = ref(false)
    const route = computed(() => root.$route)
    watch(route, () => nav.value = false)

    // ----------------------

    useScroll()
    const { enter, leave } = useTransition({ root, refs })

    // ----------------------

    const search = computed(() => root.$store.getters['app/search'])

    return { onWheel, pageKey, leave, enter, done, nav, search }
  }
}
</script>

<style lang="stylus">
@import '~reset-css/reset.css'
@import '~@/config/Settings'
@import '~@/commons/fonts/Fonts'
@import '~@/commons/stylus/Global'
@import '~@/commons/stylus/Style'

#App
  transition opacity 1s
  background: colors.grey.dark
  opacity: 0
  overflow-x: hidden
  &.is-ready
    opacity: 1

  &__mask
    position fixed
    top 0
    left 0
    width 100%
    height 100%
    transform translateY(-100%)
    background: colors.grey.dark
    z-index 2

.Page
  box-sizing border-box
  // overflow-x hidden
  background: colors.grey.dark
  min-height 100vh
  // position relative
  // z-index 1

.Page__container
  position relative
  width pxl(1293)
  margin-left auto
  margin-right auto
  &.is-small
    width pxl(1068)
    +portrait()
      width pxp(336)
  +portrait()
    width pxp(336)

// ----------------------------------------------

.highlight
  highlight()
.h1
  h1()
.h1-alt
  h1-alt()
.h2
  h2()
.h2-alt
  h2-alt()
.h3
  h3()
.h4
  h4()
.h5
  h5()
.subtitle1
  subtitle1()
.subtitle2
  subtitle2()
.body1
  body1()
.body2
  body2()
.sections
  sections()
.button1
  button1()
.button2
  button2()
.important
  important()
.caption
  caption()
.lien1
  lien1()
.lien2
  lien2()


// ----------------------------------------------

//
p = gutter.component.landscape
pp = gutter.component.portrait

.Component.is-light + .is-light
  padding-top 0

.Component.is-light
  padding-top p
  padding-bottom p
  margin-top 0
  margin-bottom 0
  +portrait() {
		padding-top pp
		padding-bottom pp
  }

.Component
  margin-top p
  margin-bottom p
  box-sizing border-box
  transform translate3d(0, 0, 0)
  +portrait() {
    margin-top pp
    margin-bottom pp
  }

.Component
  .Component
    margin 0

.is-dark
  color: colors.white
  background: colors.grey.dark
  border-color: colors.white
  svg
    fill: colors.white

.is-light
  background: colors.grey.light
  color: colors.grey.dark
  border-color: colors.black
  svg
    fill: colors.black

</style>
