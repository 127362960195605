<template>
  <div class="LatestArticles Component is-light">
    <template v-if="articles">
      <div class="Page__container is-small">
        <Section v-if="portrait" :text="ui.news" />
        <SplitText type="line">
          <h2 class="h2">{{ ui.the_news }}</h2>
        </SplitText>
      </div>
      <div class="LatestArticles__content Page__container">
        <Section v-if="landscape" :text="ui.news" />
        <div class="LatestArticles__blocks">
          <ArticlePreview
            v-for="(article, index) in articles"
            :key="index"
            :article="article"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, watch, ref } from '@vue/composition-api'
import { useQuery } from 'villus'
import ArticlePreview from '@/components/article-preview/ArticlePreview'

export default {
  name: 'LatestArticles',
  props: {
    categories: { default: () => [] },
    tags: { default: () => [] },
  },
  components: { ArticlePreview },
  setup(props, { root }) {
    const relatedQuery = []

    if(props.categories.length > 0)
      relatedQuery.push(`{field: ID, operator: EXISTS, terms: [${props.categories.join(',')}], taxonomy: CATEGORY}`)

    if(props.tags.length > 0)
      relatedQuery.push(`{field: ID, operator: EXISTS, terms: [${props.tags.join(',')}], taxonomy: TAG}`)

    const articlesQuery = type => {
      const searchIn = type == 'creation'
      ? `metaQuery: {metaArray: {key: "content_creation", value: "${root.$route.params.id}"}}`
      : `taxQuery: {relation: OR, taxArray: [${relatedQuery.join(',')}]}`

      return `
        articles(first: 3, where: {
          orderby: {field: DATE, order: DESC}
          ${searchIn}
        } ) {
          nodes {
            date
            databaseId
            acf { isExternal ${root.af('externalUrl')} content { ${root.af('title')} cover { sourceUrl(size: MEDIUM) } } }
          }
        }
      `
    }

    const { data, error, done } = useQuery({
      query: computed(() => `{
        articleRelatedToCreation: ${articlesQuery('creation')}
        ${articlesQuery()}
      }`)
    })

    const articles = ref(null)

    watch(data, () => {
      if(!data.value) return
      articles.value = [...data.value.articleRelatedToCreation.nodes, ...data.value.articles.nodes]
      // articles.value.sort((a, b) => new Date(b.date) - new Date(a.date))
      articles.value = _.take(articles.value, 3)
    })

    return { data, articles }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.LatestArticles

  &__content
    display flex
    justify-content flex-end
    margin-top pxl(104)
    +portrait()
      margin pxp(50) 0 0 0
      width 100%

  &__blocks
    display flex
    width pxl(969)
    +portrait()
      scrollX()

  .ArticlePreview
    margin-right pxl(42)
    &:last-child
      margin-right 0
    +portrait()
      margin-right: gutter.portrait

</style>
