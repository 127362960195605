<template>
  <!--Parallax :y="y"-->
    <Viewport>
      <div class="Section">
        <div class="Section__rotate sections">
          <div class="Section__text" v-html="text" />
        </div>
      </div>
    </Viewport>
  <!--/Parallax-->
</template>

<script>
export default {
  name: 'Section',
  props: {
    text: { default: '' }
  },
  setup(props, { root }) {
    let y = _.sample([_.random(-100, -50), _.random(50, 100)])
    if(root.portrait)
      y = 0
    return { y }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Section
  +portrait()
    margin-bottom pxp(40)

  +landscape()
    overflow hidden
    writing-mode vertical-rl
    position absolute
    white-space nowrap
    left 0
    margin-left pxl(-1)

  &.is-in-viewport
    .Section__text
      transform translateX(0%)

  &__rotate
    +landscape()
      transform rotate(-180deg)

  &__text
    transform translateX(100%)
    transition transform 1.5s
    +portrait()
      transform translateX(-100%)

</style>
