<template>
  <div class="CirclePlus">
    <Icon type="cross" />
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon'
export default {
  name: 'CirclePlus',
  components: { Icon }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.CirclePlus
  border: 1px solid colors.red
  position relative
  mq(
    width 50 50,
    height 50 50,
    border-radius 50 50
  )

  .Icon
    top 50%
    left 50%
    position absolute
    transform translate(-50%, -50%) rotate(45deg)
    mq(
      width 15 15,
      height 15 15
    )

</style>

