<template>
  <div class="LangSwitcher">
    <template v-if="sites">
      <router-link
        class="LangSwitcher__lang"
        v-if="site.handle != lang"
        :to="{ name: $route.meta.name + '.' + site.handle, params: $route.params }"
        :key="site.handle" v-for="site in sites">
          {{ site.handle }}
      </router-link>
    </template>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { useQuery } from 'villus'

export default {
  name: 'LangSwitcher',
  setup(props, { root }) {
    const sites = ref(null)
    const { data, error, done } = useQuery({
      query: computed(() => `{
        translations { acf { sites { handle baseUrl } } }
      }`)
    })

    watch(data, () => {
      if(data.value)
        sites.value = data.value.translations.acf.sites
    })

    return { sites }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'
@import '~@/commons/stylus/Style'

.LangSwitcher
  display: flex

  &__lang
    border 1px solid colors.white
    display flex
    box-sizing border-box
    justify-content center
    button2()
    mq(
      width 36 36,
      line-height 36 36,
      height 36 36,
      border-radius 36 36,
      margin-left 10 10
    )
    &:first-child
      margin-left: 0

</style>
