<template>
  <div class="RobertLepage">
    <template v-if="data">

      <div class="Component is-light">
        <IntroText :data="{
          title: data.page.title,
          cover: data.page.acf.intro.cover
        }">
          <SplitText type="line">
            <h2 class="h2" v-html="data.page.acf.intro.title" />
          </SplitText>
        </IntroText>
      </div>

      <div class="RobertLepage__bio Component is-light">
        <BlockFlexible :data="{
          textColumn: true,
          text: data.page.acf.intro.bio,
          sections: data.page.acf.intro.label,
          btn: data.page.acf.intro.btn
        }" />
      </div>

      <Gallery :data="data.page.acf.gallery" />
      <Publications :publications="data.page.acf.publications" />
      <Awards :key="$route.meta.name + $route.params" />

      <div class="Component is-light">
        <Cta :data="data.page.acf.cta" />
      </div>
      <LatestArticles />
    </template>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useQuery } from 'villus'
import BlockFlexible from '@/components/block-flexible/BlockFlexible'
import BtnQuery from '@/components/btn/BtnQuery'
import Gallery from '@/components/gallery/Gallery'
import Publications from '@/components/publications/Publications'
import Awards from '@/components/awards/Awards'
import Cta from '@/components/cta/Cta'
import CtaQuery from '@/components/cta/CtaQuery'
import LatestArticles from '@/components/latest-articles/LatestArticles'
import IntroText from '@/components/intro-text/IntroText'

export default {
  name: 'RobertLepage',
  components: { BlockFlexible, Gallery, Publications, Awards, Cta, LatestArticles, IntroText },
  setup(props, { root }) {
    const { data, error, done } = useQuery({
      query: computed(() => `{
          page(id: ${root.$route.meta.id}, idType: DATABASE_ID) { title acf: acf_robert_lepage {
            intro { ${root.af('bio')} ${root.af('label')} ${root.af('title')} ${BtnQuery(root)} cover { title sourceUrl(size: LARGE) } }
            gallery { title sourceUrl(size: LARGE) }
            publications { ${root.af('title')} url }
            ${CtaQuery(root)}
          }}
        }`)
    })

    return { data }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.RobertLepage
  min-height 100vh

  .highlight
    text-transform inherit

  .LatestArticles.Component
    padding-top: gutter.component.landscape

</style>
