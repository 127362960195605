<template>
  <div class="Creation">
    <template v-if="data">
      <div class="Creation__header">
        <CoverGradient :cover="data.creation.acf.content.cover" />
        <div class="Creation__title Page__container is-small">
          <SplitText type="line" renderMargin="-20% 0%">
            <div class="caption">{{ data.creation.categories.nodes[0].acf.title }}</div>
          </SplitText>
          <SplitText renderMargin="-20% 0%">
            <h1 class="h1-alt">{{ data.creation.acf.content.title }}</h1>
          </SplitText>
        </div>
      </div>
      <div class="Page__container is-small Component">
        <SplitText type="line">
          <h2 class="h2-alt" v-html="data.creation.acf.content.introduction" />
        </SplitText>
      </div>
      <div class="Page__container Component">
        <Section :text="ui.synopsis" />
        <BlockFlexible :data="{
          text: data.creation.acf.content.synopsis,
          textColumn: true
        }" />
      </div>
      <Gallery v-if="data.creation.acf.gallery" :data="data.creation.acf.gallery" />
      <Publications v-if="data.creation.acf.publications" :publications="data.creation.acf.publications" />
    </template>
    <Calendars v-if="data" type="new" />
    <Calendars v-if="data" type="old" />
    <Teaser />
    <Credits v-if="data" :credits="data.creation.acf.credits" />
    <Awards />
    <LatestArticles
      v-if="relatedIds.categories.length > 0 || relatedIds.tags.length > 0"
      :categories="relatedIds.categories"
      :tags="relatedIds.tags"
    />
    <PrevNext />
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { useQuery } from 'villus'
import CoverGradient from '@/components/cover-gradient/CoverGradient'
import Gallery from '@/components/gallery/Gallery'
import Calendars from '@/components/calendars/Calendars'
import Publications from '@/components/publications/Publications'
import Credits from '@/components/credits/Credits'
import LatestArticles from '@/components/latest-articles/LatestArticles'
import PrevNext from '@/components/prev-next/PrevNext'
import BlockFlexible from '@/components/block-flexible/BlockFlexible'
import Awards from '@/components/awards/Awards'
import Teaser from '@/components/teaser/Teaser'

export default {
  name: 'Creation',
  components: { CoverGradient, Gallery, Calendars, Publications, Credits, LatestArticles, PrevNext, BlockFlexible, Awards, Teaser },
  setup(props, { root }) {

    const relatedIds = ref({ categories: [], tags: [] })

    const { data, error, done } = useQuery({
      query: computed(() => `{
          creation(id: ${root.$route.params.id}, idType: DATABASE_ID) {
            acf {
              gallery {
                title
                sourceUrl(size: MEDIUM)
              }
              content {
                ${root.af('title')}
                cover { sourceUrl(size: LARGE) }
                ${root.af('synopsis')}
                ${root.af('introduction')}
              }
              publications { ${root.af('title')} url }
            }
            categories: ccategories { nodes { databaseId acf { ${root.af('title')} } } }
          }
        }`)
    })

    // tags { nodes { databaseId acf { ${root.af('title')} } } }

    watch(data, () => {
      if(!data.value) return
      _.each(data.value.creation.categories.nodes, node => relatedIds.value.categories.push(`"${node.databaseId}"`))
      // _.each(data.value.creation.tags.nodes, node => relatedIds.value.tags.push(`"${node.databaseId}"`))
    })

    return { data, relatedIds }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Creation

  &__header
    height 100vh
    position relative
    padding-top .1px

  &__title
    position relative
    mq(margin-top 500 200)
    pointer-events none
    +portrait()
      margin-left: pxp(70)
    .caption
      margin-bottom pxl(10)
      +portrait()
        margin-bottom pxp(10)

</style>
