export default (root, force) => `
  btn: button {
    hasButton
    ${root.af('label', force)}
    page {
      ... on Page {
        acf { settings { handle } }
      }
      ... on Creation {
        databaseId
        acf { content { ${root.af('title', force)} } }
      }
      ... on Article {
        databaseId
        acf { content { ${root.af('title', force)} } }
      }
    }
    type
    url
    ${root.af('file')} {
      mediaItemUrl
    }
  }
`
