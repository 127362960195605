<script>
import { onMounted, onUnmounted } from '@vue/composition-api'

export default {
  name: 'Viewport',
  abstract: true,
  render () { return this.$slots.default[0] },
  props: {
    rootMargin: { default: '0px 0px 0px 0px' }
  },
  setup(props, { root, slots, emit, refs }) {
    let isVirgin = true
    const leave = el => {
      el.classList.remove('is-in-viewport')
      emit('leave', el)
    }
    const enter = el => {
      isVirgin = false
      emit('enter', el)
      el.classList.add('is-in-viewport')
    }
    const observer = new IntersectionObserver(entries => {
      _.each(entries, entry => {
        if(entry.isIntersecting)
          enter(entry.target)

        if(!entry.isIntersecting && !isVirgin)
          leave(entry.target)
      })
    }, {
      threshold: [0],
      rootMargin: props.rootMargin
    })

    onMounted(() => observer.observe(slots.default()[0].elm))
    onUnmounted(() =>  observer.disconnect())
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Viewport

  &__fade
    opacity 0
    transition opacity 2s
    &.is-in-viewport
      opacity 1

  &__row
    transform translateX(pxl(-40))
    opacity 0
    transition transform 1s, opacity 1s
    &.is-in-viewport
      transform translateX(0px)
      opacity 1

  &__scalefade
    transform scale(.9)
    opacity 0
    transition transform 1s, opacity 1s
    &.is-in-viewport
      transform scale(1)
      opacity 1

</style>
