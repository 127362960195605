<template>
  <div class="Contact">

    <template v-if="data">

      <div class="Component is-light">
        <IntroText :data="{
          title: data.page.acf.intro.title,
          cover: data.page.acf.intro.cover
        }">
          <SplitText type="line">
            <div class="Contact__intro-title h3" v-html="data.page.acf.intro.text" />
          </SplitText>
          <Viewport>
            <div class="Contact__intro-block">
              <div class="Contact__label caption">{{ data.general.acf.address.label }}</div>
              <div class="important" v-html="data.general.acf.address.address" />
            </div>
          </Viewport>
          <Viewport>
            <div class="Contact__intro-block">
              <div class="Footer__label caption">{{ data.general.acf.contact.label }}</div>
              <a :href="'tel:' + data.general.acf.contact.email" class="important">{{ data.general.acf.contact.phone }}</a>
              <a :href="'mailto:' + data.general.acf.contact.email" class="important">{{ data.general.acf.contact.email }}</a>
            </div>
          </Viewport>
        </IntroText>
      </div>

      <div class="Contact__contact-us Component">
        <div class="Page__container is-small">
          <SplitText type="line">
            <h2 class="h2" v-html="data.page.acf.contactUs.label" />
          </SplitText>
        </div>

        <div class="Page__container Contact__section">
          <Section :text="data.page.acf.contactUs.direct.label" />
          <div class="Page__container is-small Contact__direct">
            <Viewport :key="index" v-for="(block, index) in data.page.acf.contactUs.direct.list">
              <div class="Viewport__scalefade Contact__direct-block" >
                <div>
                  <div class="subtitle1">{{ block.title }}</div>
                  <a target="_blank" :href="'mailto:' + block.email" class="body1">{{ block.email }}</a>
                </div>
                <div>
                  <div class="caption">{{ ui.phone }}</div>
                  <a target="_blank" :href="'tel:' + block.phone" class="body1">{{ block.phone }}</a>
                </div>
              </div>
            </Viewport>
          </div>
        </div>

        <div class="Page__container  Contact__section is-agents">
          <Section :text="data.page.acf.contactUs.agents.label" />
          <div class="Page__container is-small Contact__agents">
            <Viewport :key="index" v-for="(block, index) in data.page.acf.contactUs.agents.list">
              <div class="Viewport__scalefade Contact__agents-block">
                <div>
                  <a target="_blank" :href="block.website" class="subtitle1">{{ block.title }}</a>
                </div>
                <div>
                  <a target="_blank" :href="block.website" class="body1">{{ block.text }}</a>
                </div>
                <div class="Contact__links">
                  <a target="_blank"
                    :href="(link.key == 'email' ? 'mailto:' : '') + link.link"
                    :class="'is-' + link.key"
                    class="Contact__link" v-for="link in block.links">
                    <Icon :type="link.key" />
                  </a>
                </div>
              </div>
            </Viewport>
          </div>
        </div>

      </div>

      <Cta :data="data.page.acf.cta" />
    </template>

  </div>
</template>

<script>
import Cta from '@/components/cta/Cta'
import CtaQuery from '@/components/cta/CtaQuery'
import { computed } from '@vue/composition-api'
import { useQuery } from 'villus'
import Cover from '@/components/cover/Cover'
import Icon from '@/components/icon/Icon'
import IntroText from '@/components/intro-text/IntroText'

export default {
  name: 'Contact',
  components: { Cta, Cover, Icon, IntroText },
  setup(props, { root }) {
    const { data, error, done } = useQuery({
      query: computed(() => `{
          general {
            acf {
              contact { ${root.af('label')} phone email }
              address { ${root.af('label')} ${root.af('address')} }
            }
          }
          page(id: ${root.$route.meta.id}, idType: DATABASE_ID) { acf: acf_contact {
            intro { ${root.af('text')} ${root.af('title')} cover { title sourceUrl(size: LARGE) }}
            contactUs {
              ${root.af('label')}
              direct { ${root.af('label')} list { email phone title } }
              agents { ${root.af('label')} list { title ${root.af('text')} website links { key link } } }
            }
            ${CtaQuery(root)}
          }}
        }`)
    })
    return { data }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Contact
  min-height 100vh

  .IntroText
    &__highlight
      +landscape()
        padding-top pxl(200)

  &__links
    display flex
    align-items center
    mq(margin-top 30 20)
    a
      svg
        mq(
          margin-right 20 15,
          width 12 12,
          height 12 12
        )
      &.is-email
        svg
          mq(
            width 16 16,
            height 12 12
          )

  &__agents
    border-top: 1px solid colors.white
    display flex
    padding pxl(30) 0
    +portrait()
      padding pxp(30) 0
      flex-direction column
    &-block
      flex-grow 0
      flex-shrink 0
      white-space pre-line
      mq(
        margin-right 35 0,
        width 295 100%
      )
      +portrait()
        margin-bottom pxp(50)
        &:last-child
          margin-bottom 0

  .Section
    +portrait()
      margin-bottom pxp(40)

  &__section
    mq(margin-top 70 50)
    &.is-agents
      mq(margin-top 145 70)

  &__direct
    &-block
      display flex
      padding pxl(30) 0
      align-items center
      border-top: 1px solid colors.white
      +portrait()
        flex-direction column
        padding pxp(30) 0
      div
        flex-grow 0
        flex-shrink 0
        &:nth-child(2)
          +portrait()
            margin-top pxp(20)
        mq(
          margin-right 35 0,
          width 295 100%
        )

  &__contact-us
    display block
    margin-bottom 0

  &__intro
    &-block
      opacity 0
      transform translateX(pxl(20))
      transition opacity 1s, transform 1s
      mq(margin-top 65 40)
      &.is-in-viewport
        opacity 1
        transform translateX(0)
      a
        display block

  .IntroText
    +landscape()
      &__content
        flex-direction row-reverse
        align-items center
      &__cover
        height pxl(790)
        margin-bottom pxl(-265)
      &__text
        margin-right pxl(145)
        width pxl(405)
        .important
          white-space pre-line


</style>
