export default {
  computed: {
    ui() { return this.$store.getters['app/ui'] },
    landscape() {
      const sizes = this.$store.getters['app/windowSizes']
      return sizes.width > sizes.height
    },
    portrait() { return !this.landscape }
  },
  methods: {
    af(alias, lang) {
      lang = lang ? lang : this.lang
      const cap = lang.charAt(0).toUpperCase() + lang.slice(1)
      return `${alias}: ${alias}${cap}`
    },
    kebab: s => _.kebabCase(s),
    showCursor(type, color) {
      this.$store.commit('app/SET_CURSOR', { type: type, color: color })
    },
    hideCursor() {
      this.$store.commit('app/SET_CURSOR', false)
    }
  }
}
