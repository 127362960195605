<template>
  <div class="RedCircle">
    <svg class="RedCircle__svg" viewBox="0 0 100 100">
      <circle ref="circle" cx="50" cy="50" r="48" />
    </svg>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import { watch, onMounted, computed } from '@vue/composition-api'
gsap.registerPlugin(DrawSVGPlugin)

export default {
  name: 'RedCircle',
  props: {
    active: { default: false }
  },
  setup(props, { root, refs }) {
    const active = computed(() => props.active)

    onMounted(() => {
      gsap.set(refs.circle, { drawSVG: '0% 0%' })

      watch(active, () => {
        if(!active.value)
          gsap.to(refs.circle, { duration: 1, drawSVG: '0% 0%' })

        if(active.value)
          gsap.to(refs.circle, { duration: 1, drawSVG: '0% 100%' })
      })
    })
  }

}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.RedCircle
  position absolute
  height 100%
  width 100%

  &__svg
    position absolute
    height 100%
    width 100%
    fill none !important
    stroke-width 2 !important
    stroke: colors.red !important
    transform rotate(-90deg)

</style>
