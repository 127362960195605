<template>
  <Viewport rootMargin="-10% 0%" @enter="render" >
    <Viewport rootMargin="10% 0%" @enter="set" >

      <component 
        v-bind="articleType"
        class="ArticlePreview" :class="{ 'has-hover-cover': hoverCover }">
        <kinesis-container>
          <kinesis-element v-if="$route.meta.name == 'articles'" class="ArticlePreview__cover" :strength="10" type="depth">
            <Cover :cover="article.acf.content.cover" />
          </kinesis-element>
          <div class="ArticlePreview__top caption">
            <div ref="top">
              {{ topText.part1 }} <span>{{ topText.part2 }}</span>
            </div>
          </div>
          <div ref="border" class="ArticlePreview__border" />
          <div class="ArticlePreview__title">
            <div ref="bottom">
              <h4 class="h4">{{ article.acf.content.title }}</h4>
              <div class="ArticlePreview__arrow"><Icon type="arrow" /></div>
            </div>
          </div>
        </kinesis-container>
      </component>

    </Viewport>
  </Viewport>
</template>

<script>
import { format, parseISO } from 'date-fns'
import Cover from '@/components/cover/Cover'
import Icon from '@/components/icon/Icon'
import { gsap } from 'gsap'

export default {
  name: 'ArticlePreview',
  components: { Cover, Icon },
  props: {
    hoverCover: { default: true },
    article: { default: null }
  },
  setup(props, { root, refs }) {
    const d = parseISO(props.article.date)
    const topText = { part1: format(d, '(dd)'), part2: format(d, 'MM.yyyy') }

    const set = () => {
      gsap.set(refs.top, { y: '200%' })
      gsap.set(refs.border, { scaleX: 0 })
      gsap.set(refs.bottom, { y: '-100%' })
    }

    const render = () => {
      gsap.to(refs.border, { scaleX: 1 })
      gsap.to([refs.top, refs.bottom], { delay: .5, y: '0%' })
    }

    let articleType = {
      is: 'router-link',
      to: { name: 'article.' + root.lang, params: { id: props.article.databaseId, slug: root.kebab(props.article.acf.content.title) } }
    }

    if(props.article.acf.isExternal)
      articleType = { is: 'a', target: '_blank', href: props.article.acf.externalUrl }

    return { topText, set, render, articleType }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.ArticlePreview
  display block
  position relative
  flex-shrink 0
  flex-grow 0
  mq(
    width 295 260
  )

  &:hover
    .ArticlePreview__border
      border-color: colors.red
    .ArticlePreview__arrow
      opacity 1
      transform translateX(0px)

  &.has-hover-cover
    &:hover
      .Cover
        opacity 1

  &__arrow
    opacity 0
    transition opacity .5s, transform .5s
    transform translateX(pxl(-20))
    mq(margin-top 25 20)

  &__cover
    position absolute
    width pxl(185)
    height pxl(240)
    pointer-events none
    transition opacity .5s
    top -50%
    right 0
    +portrait()
      display none
    .Cover
      opacity 0
      transition opacity .5s

  &__border
    border-bottom-width 1px
    border-style solid

  &__top
    position relative
    transition border .5s
    overflow hidden
    > div
      mq(padding-bottom 15 15)
    span
      mq(margin-left 88 88)

  &__title
    position relative
    overflow hidden
    > div
      mq(padding-top 30 30)


</style>
