<template>
  <div class="Categories Component is-dark">
      <template v-if="data">
        <div class="Page__container">
          <Section :text="data.page.acf.intro.title" />
        </div>

        <div class="swiper-container Categories__blocks" ref="swiper">
          <div class="swiper-wrapper">

            <kinesis-container class="swiper-slide Categories__block" :key="category.databaseId" v-for="category in data.categories.nodes">
              <kinesis-element :strength="10" type="depth">
                <router-link
                  :to="{ name: 'creations.' + lang, params: { category_id: category.databaseId }  }"
                  @mouseenter.native="showCursor('explore')"
                  @mouseleave.native="hideCursor"
                  class="Categories__cover">
                  <Cover :cover="category.acf.cover" />
                  <kinesis-element :strength="-10" type="translate">
                    <Viewport>
                      <div class="Viewport__fade Categories__title h2-alt">{{ category.acf.title }}</div>
                    </Viewport>
                  </kinesis-element>
                </router-link>
                <Viewport>
                  <div class="Categories__bottom">
                    <span class="caption">({{ category.creations.nodes.length }})</span>
                    <span class="caption">{{ ui.productions }}</span>
                  </div>
                </Viewport>
              </kinesis-element>
            </kinesis-container>

          </div>
        </div>

      </template>

      <div class="Categories__next" @click="next">
        <Icon type="circleArrow" />
      </div>

      <div class="Categories__btn-more">
        <router-link :to="{ name: 'creations.' + lang }">
          <BtnMore :text="ui.show_all_creations" />
        </router-link>
      </div>

  </div>
</template>

<script>
import { computed, watch, ref, onMounted } from '@vue/composition-api'
import { useQuery } from 'villus'
import Cover from '@/components/cover/Cover'
import Icon from '@/components/icon/Icon'
import BtnMore from '@/components/btn-more/BtnMore'
import Swiper from 'swiper/js/swiper.min.js'
require('swiper/css/swiper.css')

export default {
  name: 'intro',
  components: { Cover, Icon, BtnMore },
  setup(props, { root, refs }) {
    const categories = ref([])
    const { route: { meta: { id } } } = root.$router.resolve({ name: 'creations.' + root.lang })
    const { data, error, done } = useQuery({
      query: computed(() => `{
        page(id: ${id}, idType: DATABASE_ID) { acf: acf_creations { intro { ${root.af('title')} } } }
        categories: ccategories {
          nodes { databaseId acf { ${root.af('title')} cover { sourceUrl(size: LARGE) } } creations { nodes { id } } }
        }
      }`)
    })

    // -----------------

    let swiper = null

    onMounted(() => {

      watch(data, () => {
        if(!data.value) return

        swiper = new Swiper(refs.swiper, {
          speed: 800,
          breakpoints: {
            1023: {
              slidesPerGroup: 3,
              slidesPerView: 'auto',
            }
          }
        })

      })

    })

    const next = () => {
      const max = root.landscape ? 2 : 5

      if(swiper.activeIndex == max)
        return swiper.slideTo(0)

      swiper.slideNext()
    }


    return { data, next }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Categories

  &__btn-more
    display block
    height pxl(15)
    margin-top pxl(-150)
    position relative
    display flex
    justify-content center
    padding-bottom: gutter.component.landscape
    z-index 2
    +portrait()
      height auto
    .BtnMore
      padding-top 0

  &__next
    position absolute
    right gutter.landscape
    bottom 0
    cursor pointer
    z-index 3
    +portrait()
      bottom auto
      top pxp(-10)

  &__blocks
    display flex
    justify-content space-between
    transition opacity .5s
    padding-bottom pxl(50)
    &-enter
    &-leave-active
      opacity 0

  &__block
    width pxl(405)
    margin-right pxl(35)
    box-sizing border-box
    &:first-child
      margin-left: gutter.landscape
    &:nth-child(1)
    &:nth-child(4)
      margin-top: gutter.component.landscape
    &:nth-child(3)
    &:nth-child(6)
      margin-top: gutter.landscape
    &:nth-child(3n)
      margin-right: pxl(80)
    +portrait()
      margin-left: gutter.portrait
      margin-right: gutter.portrait
      margin-top: 0 !important
      width pxp(335) !important

  &__bottom
    padding-top pxl(10)
    transform translateY(-100%)
    transition transform 1s
    transition-delay .5s
    &.is-in-viewport
      transform translateY(0%)
    mq(padding-top 10 15)
    span
      &:nth-child(2)
        margin-left pxl(100)

  &__cover
    position relative
    display flex
    align-items center
    justify-content center
    z-index 1
    mq(height 586 463)

  &__title
    position relative
    text-transform lowercase

</style>
