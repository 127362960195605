<template>
  <div class="Cover">
    <div ref="color" class="Cover__color" />
    <Parallax :y="y">
      <div class="Cover__parallax" :style="{ top: -y + 'px', bottom: -y + 'px' }">
        <Viewport @enter="render" rootMargin="-40% 0% -40% 0%">
          <Viewport @enter="set" rootMargin="20% 0% 20% 0%">
            <div class="Cover__viewport">
              <div ref="cover" v-if="cover && lazy" class="Cover__bg Vlazy" v-lazy:background-image="cover.sourceUrl" />
                <div ref="cover" v-if="cover && !lazy" class="Cover__bg" :style="{ backgroundImage: `url(${cover.sourceUrl})` }" />
            </div>
          </Viewport>
        </Viewport>
      </div>
    </Parallax>
    <div class="Cover__caption caption" v-if="caption && cover">
      <div class="Cover__caption-info">©</div>
      <div class="Cover__caption-title">{{ cover.title }}</div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { onMounted } from '@vue/composition-api'

export default {
  name: 'Cover',
  props: {
    cover: { default: null },
    lazy: { default: true },
    caption: { default: false },
    appear: { default: true }
  },
  setup(props, { refs }) {

    const set = el => {
      if(!props.appear) return
      if(el)
        gsap.set(el, { scale: 1.1, alpha: 0 })
      if(refs.color)
        gsap.set(refs.color, { scale: .7, alpha: 0 })
    }

    onMounted(() => {
      set()
    })

    const render = el => {
      if(!props.appear) return
      if(refs.color)
        gsap.to(refs.color, { duration: 1, ease: 'expo.out', scale: 1, alpha: 1 })
      if(el)
        gsap.to(el, { delay: .5, duration: 1, ease: 'expo.out', scale: 1, alpha: 1 })
    }

    const y = _.random(20, 60)

    return { set, render, y }

  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Cover
  position absolute
  right 0
  left 0
  top 0
  bottom 0
  overflow hidden

  &__caption
    position absolute
    bottom 0
    right 0
    left 0
    padding pxl(10)
    color: colors.white
    +portrait()
      padding pxp(10)
    &:hover
      .Cover__caption-title
        transform translateY(0)
    &-info
      position relative
      z-index 1
    &-title
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      background: rgba(colors.black, .5)
      padding pxl(10) pxl(10) pxl(10) pxl(30)
      transform translateY(100%)
      transition transform .5s
      +portrait()
        padding pxp(10) pxp(10) pxp(10) pxp(30)
  &__color
  &__bg
  &__viewport
  &__parallax
    position absolute
    top 0
    left 0
    bottom 0
    right 0

  &__color
    background rgba(black, .9)
    transform-origin bottom center

  &__bg
    background-position center
    background-size cover

</style>
