<template>
  <div>
    <div class="Awards Component" v-if="awards.length > 0" :key="$route.meta.name">

      <div class="Page__container">
        <Section :text="ui.prize" />
        <div class="Awards__content Page__container is-small">
          <SplitText type="line">
            <h2 class="h2" v-html="ui.distinction" />
          </SplitText>
          <div class="Awards__blocks">

            <Viewport v-for="(award, index) in awards" :key="index">
              <div class="Awards__block Viewport__scalefade">
                <div class="caption" v-html="parseInt(award.date)" />
                <div>
                  <div class="subtitle1" v-html="award.acf.name" />
                  <div class="body2" v-html="award.acf.type" />
                  <div class="subtitle1">
                    <router-link
                      v-if="award.acf.creation && $route.meta.name != 'creation'"
                      :to="{ name: 'creation.' + lang, params: { id: award.acf.creation.databaseId, slug: kebab(award.acf.creation.acf.content.title) } }">
                      {{ award.acf.creation.acf.content.title }}
                    </router-link>
                  </div>
                </div>
              </div>
            </Viewport>

          </div>

          <BtnMore @click="next = pageInfo.endCursor" v-if="pageInfo && pageInfo.hasNextPage" />

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import BtnMore from '@/components/btn-more/BtnMore'
import { useQuery } from 'villus'

export default {
  name: 'Awards',
  components: { BtnMore },
  setup(props, { root }) {
    const awards = ref([])
    const pageInfo = ref({})
    const next = ref('')
    const { data, error, done } = useQuery({
      query: computed(() => {
        let filter = 'where: { orderby: {field: DATE, order: DESC} }'

        if(root.$route.meta.name == 'creation')
          filter = `where: {orderby: {field: DATE, order: DESC}, metaQuery: {metaArray: {key: "creation", value: "${root.$route.params.id}"}}}`

        return `{
          awards(
            before: "${next.value}"
            first: 12
            ${filter}
          ) {
            pageInfo { hasNextPage endCursor }
            nodes {
              date
              acf {
                ${root.af('name')}
                ${root.af('type')}
                creation { ... on Creation { databaseId acf { content { ${root.af('title')} } } } }
              }
            }
          }
        }`
      })
    })

    watch(data, () => {
      if(!data.value) return
      awards.value = [...awards.value, ...data.value.awards.nodes]
      pageInfo.value = data.value.awards.pageInfo
    })

    return { awards, next, pageInfo }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Awards
  padding-top: gutter.component.landscape
  // display none
  // &.is-active
  //   display block

  &__content
    mq(margin-left 100 0)

  &__blocks
    mq(margin-top 70 40)

  &__block
    display flex
    padding pxl(30) 0
    justify-content space-between
    border-top-width 1px
    border-style solid
    +portrait()
      padding pxp(30) 0
    &:last-child
      border-bottom-width 1px
    div
      &:nth-child(1)
        mq(width 10% 20%)
      &:nth-child(2)
        display flex
        mq(width 90% 80%)
        justify-content space-between
        +portrait()
          flex-direction column
        div
          &:nth-child(1)
            mq(width 30% 100%)
          &:nth-child(2)
            mq(width 40% 100%)
            +portrait()
              margin pxp(10) 0 pxp(20)
          &:nth-child(3)
            mq(
              width 20% 100%,
              text-align right left
            )
            position relative

</style>
