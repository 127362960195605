<template>
  <div class="Partners Component">
    <div v-for="section in data" class="Page__container is-small">
      <SplitText type="line">
        <h2 class="h2" v-html="section.label" />
      </SplitText>
      <div class="Partners__logos">

        <div class="Partners__logo" v-for="logo in section.logos">
          <a target="_blank" :href="logo.url">
            <Viewport>
              <img class="Viewport__scalefade" :src="logo.logo.sourceUrl" />
            </Viewport>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Partners',
  props: {
    data: { default: null }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Partners
  display flex

  h2
    +landscape()
      max-width pxl(450)

  &__logos
    border-top 1px solid rgba(colors.white, .3)
    display flex
    align-items center
    flex-wrap wrap
    mq(
      margin-top 100 70,
    )
    +portrait()
      flex-direction column

  &__logo
    flex-shrink 0
    flex-grow 0
    display block
    padding 0 pxl(40)
    box-sizing border-box
    mq(
      height 300 200,
      width 300 200,
      margin-right 84 0
    )
    &:nth-child(3n)
      margin-right 0
    img
      width 100%
      height 100%
      object-fit contain

</style>
