<script>
import { computed, watch, ref } from '@vue/composition-api'
import { useQuery } from 'villus'

export default {
  name: 'Ui',
  render: h => h(),
  setup(props, { root }) {
    const value = ref('')
    const { data, error, done } = useQuery({
      query: computed(() => `{
        general { acf { ui { key ${root.af('label')} } }}
      }`)
    })

    watch(data, () => {
      if(data.value) {
        const ui = {}
        _.each(data.value.general.acf.ui, d => ui[d.key] = d.label)
        root.$store.commit('app/SET_UI', ui)
      }
    })
  }
}
</script>
