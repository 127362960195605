<template>
  <div class="Credits Component">
    <template v-if="data">

      <div class="Page__container is-small">
        <SplitText type="line">
          <h2 class="h2">{{ ui.credits }}</h2>
        </SplitText>
      </div>

      <div class="Page__container is-small">
        <div class="Credits__labels">
          <div class="Credits__label button2"
            v-for="tab in data.creation.acf.credits.flexibleCredits"
            @click="current = tab"
            :class="{ 'is-active': current.tab == tab.tab }"
            v-html="tab.tab" />
        </div>
        <transition name="Credits__blocks" mode="out-in">
          <div class="Credits__blocks" :key="current.tab" v-if="current">
            <div class="Credits__block" v-for="block in current.blocks">
              <div v-if="block.label" class="subtitle1" v-html="block.label" />
              <div class="body2" v-for="name in block.list" v-html="name.multilang ? name.labelMulti : name.label" />
            </div>
          </div>
        </transition>
      </div>

    </template>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { useQuery } from 'villus'

export default {
  name: 'Credits',
  setup(props, { root }) {
    const current = ref({})
    const { data, error, done } = useQuery({
      query: computed(() => {
        const q = `{
          site(handle: "${root.lang}")
          creation(id: ${root.$route.params.id}, idType: DATABASE_ID) {
            acf {
              credits {
                flexibleCredits {
                  ... on Creation_Acf_Credits_FlexibleCredits_Tab {
                    tab
                    blocks { ${root.af('label')} list { label ${root.af('labelMulti')} multilang } }
                  }
                }
              }
            }
          }
        }`
        return q
      })
    })

    watch(data, () => {
      if(data.value)
        current.value = data.value.creation.acf.credits.flexibleCredits[0]
    })

    return { data, current }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Credits
  margin-top: gutter.component.landscape
  +portrait()
    margin-top: gutter.component.portrait

  &__labels
    display flex
    mq(
      margin-top 60 30,
      margin-bottom 60 30
    )
    +portrait()
      scrollX()
      margin-left: -(gutter.portrait)

  &__label
    cursor pointer
    position relative
    mq(
      margin-right 40 40,
      padding-top 15 15
    )
    &:before
      content ''
      top 0
      left 0
      width 100%
      height 1px
      background: colors.red
      position absolute
      transform scaleX(0)
      transition transform 1s
      transform-origin left top
    &.is-active
      &:before
        transform scaleX(1)

  .subtitle1
    mq(margin-bottom 10 5)

  &__blocks
    display flex
    flex-wrap wrap
    transition opacity 1s
    +portrait()
      scrollX()
      margin-left: -(gutter.portrait)
      height pxp(400)
      flex-direction column
    &:after
      content ""
      flex auto
    &-enter
    &-leave-active
      opacity 0

  &__block
    box-sizing border-box
    flex-grow 0
    flex-shrink 0
    mq(
      width 185 216,
      margin-right 35 30,
      margin-bottom 20 30
    )
    &:nth-child(5n)
      margin-right 0

</style>

