import { gsap } from 'gsap'
import { scrollTo } from '@/components/scroll/Scroll'

export const useTransition = ({ root, refs }) => {

  const enter = (el, done) => {
    gsap.to(refs.mask, {
      onStart: () => {
        let to = 0
        // if(root.$route.params.tags_ids || (root.$route.params.category_id && root.$route.params.category_id != '_'))
        //   to = '.Filters'
        scrollTo({ duration: 0, scrollTo: to })
      },
      delay: .6,
      ease: 'ease.out',
      y: '-100%',
      onComplete: done
    })
  }

  const leave = (el, done)  => {
    gsap.to(refs.mask, { duration: .6, ease: 'expo.out', y: '0%', onComplete: done })
  }

  return { enter, leave }
}

