<template>
  <div class="Socials" :class="'is-' + type">
    <template v-if="data">
      <a v-for="social in data.general.acf.socials" class="Socials__btn" target="_blank" :href="social.url">
        <Icon :type="social.key" />
      </a>
    </template>
  </div>
</template>

<script>
import { useQuery } from 'villus'
import Icon from '@/components/icon/Icon'

export default {
  name: 'Socials',
  props: ['type'],
  components: { Icon },
  setup(props, { root }) {
    const { data, error, done } = useQuery({
      query: `{
        general { acf { socials { url key } } }
      }`
    })

    return { data }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Socials
  display flex
  mq(
    width 120 167,
  )
  justify-content space-between

  &__btn
    transition transform .5s
    &:hover
      transform scale(1.2)
    mq(
      width 15 15,
      height 15 15
    )
    svg
      fill: colors.white

</style>

