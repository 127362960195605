<template>
  <div class="IntroText">

    <Parallax :y="100">
      <div class="IntroText__circle" />
    </Parallax>

    <div class="IntroText__highlight Page__container is-small">
      <SplitText>
        <h1 class="highlight">{{ data.title }}</h1>
      </SplitText>
    </div>

    <div class="IntroText__content">
      <div class="IntroText__cover">
        <Parallax :y="100">
          <Cover :caption="true" :appear="false" :cover="data.cover" />
        </Parallax>
      </div>
      <div class="IntroText__text">
        <slot />
      </div>
    </div>

  </div>
</template>

<script>
import Cover from '@/components/cover/Cover'
export default {
  name: 'IntroText',
  components: { Cover },
  props: {
    data: { default: null },
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.IntroText

  &__circle
    position absolute
    border: 1px solid colors.red
    mq(
      left 45% -160,
      margin-left -300 0,
      top 180 130,
      width 737 400,
      height 737 400,
      border-radius 737 400
    )

  &__highlight
    padding-top pxl(70)
    +portrait()
      padding-top pxp(125)
      text-align center

  &__content
    display flex
    align-items flex-end
    margin-top pxl(90)
    +portrait()
      flex-direction column
      align-items flex-start
      margin-top pxp(90)

  &__cover
    position relative
    mq(
      width 702 356,
      height 751 400
    )

  &__text
    margin-left pxl(145)
    mq(width 405 335)
    +portrait()
      margin-top pxp(80)
      margin-left: gutter.portrait

</style>
