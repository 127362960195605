import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { gsap } from 'gsap'
import NormalizedWheel from './normalizeWheel'
gsap.registerPlugin(ScrollToPlugin)

let rafID = null
let y = { target: 0, current: 0, last: 0 }
let scrollContainer = document.documentElement

export let velocity = 0
export let direction = 'down'

export const syncPos = () => _.each(y, (yVal, k) => y[k] = scrollContainer.scrollTop)

export const setContainer = (container) => {
  scrollContainer = container
  syncPos()
}

export const resetContainer = () => {
  scrollContainer = document.documentElement
  syncPos()
}

export const useScroll = () => {
  onMounted(() => {
    syncPos()
  })
  onUnmounted(() => {
    cancelAnimationFrame(rafID)
    rafID = null
  })
}

export const scrollTo = (obj) => {
  gsap.to(scrollContainer, { ...obj,
    onStart: () => {
      cancelAnimationFrame(rafID)
      if(obj.onStart) obj.onStart()
    },
    onComplete: () => {
      syncPos()
      rafID = null
      if(obj.onComplete) obj.onComplete()
    }
  })
}

const run = () => {
  y.current += ((y.target - y.current)) * .1
  y.current = +y.current.toFixed(1)

  // scrollContainer.scrollTop = y.current
  window.scrollTo(0, y.current)

  if(y.last == y.current)
    return rafID = null
  y.last = y.current

  velocity = parseInt(y.target - y.current)

  rafID = requestAnimationFrame(run)
}

export const onWheel = (e) => {
  e.preventDefault()
  direction = e.wheelDelta < 0 ? 'down' : 'up'
  y.target += NormalizedWheel(e).pixelY
  y.target = Math.min(y.target, scrollContainer.scrollHeight - window.innerHeight)
  y.target = Math.max(y.target, 0)
  if(rafID === null) run()
}
