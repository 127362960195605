<template>
  <div class="BtnMore button1">
    <div class="BtnMore__content" @click="$emit('click')">
      {{ text ? text : ui.show_more }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BtnMore',
  props: {
    text: { default: false }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.BtnMore
  padding-top: (gutter.component.landscape / 2)
  +portrait()
    padding-top: (gutter.component.portrait / 2)

  &__content
    display flex
    justify-content center
    align-items center
    margin 0 auto
    cursor pointer
    position relative
    box-sizing border-box
    text-align center
    &:hover
      &:before
        opacity 1
        transform scale(1)
    +portrait()
      &:before
        opacity 1
        transform scale(1)
    &:before
      border: 1px solid colors.red
      content ''
      position absolute
      width 100%
      height 100%
      opacity 0
      border-radius 100%
      transition transform 1s, opacity 1s
      +landscape()
        transform scale(.8)
    mq(
      width 188 170,
      height 188 170,
      padding 25 10,
      line-height 25 25
    )

</style>
