<template>
  <div class="CalendarPage">
    <Intro />
    <Calendars />
  </div>
</template>

<script>
import Intro from '@/components/intro/Intro'
import { computed, ref } from '@vue/composition-api'
import Calendars from '@/components/calendars/Calendars'

export default {
  name: 'Creations',
  components: { Intro, Calendars },
  setup(props, { root }) {
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.CalandarPage
  width: auto

</style>

