var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"HomeRobertLepage Component is-light"},[_c('Viewport',{attrs:{"rootMargin":"0% 0% -50%"}},[_c('div',{staticClass:"HomeRobertLepage__band"})]),(_vm.data)?_c('BlockFlexible',{attrs:{"data":{
    title: _vm.data.page.acf.robertLepage.title,
    text: _vm.data.page.acf.robertLepage.text,
    cover: _vm.data.page.acf.robertLepage.cover,
    btn: _vm.data.page.acf.robertLepage.btn,
    sections: _vm.data.page.acf.robertLepage.label,
    hasCover: true,
    hasTitle: true,
  }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }