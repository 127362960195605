<template>
  <div class="Article is-light">

    <template v-if="data">

      <div class="Page__container is-small">

        <div class="Article__warning">
          <template v-if="force">
            <div v-if="force != lang">{{ ui.warning_article }}</div>
          </template>
        </div>

        <Viewport>
          <router-link :to="{ name: 'articles.' + lang, params: fromParams }" class="Article__back button2">
            <Icon type="arrow" />{{ data.page.acf.intro.title }}
          </router-link>
        </Viewport>

        <div class="Article__header">
          <Viewport v-if="data.article._date" >
            <div class="Article__top button2">
              {{ data.article._date.part1 }} <span>{{ data.article._date.part2 }}</span>
            </div>
          </Viewport>
          <SplitText type="line">
            <h1 class="Article__title h1-alt">{{ data.article.acf.content.title }}</h1>
          </SplitText>
          <div class="Article__tags">
            <Viewport
              v-for="tag in data.article.tags.nodes"
              :key="tag.databaseId"
              v-if="tag.acf"
            >
              <router-link
                :to="{ name: 'articles.' + lang, params: getTagParams(tag.databaseId) }"
                class="Article__tag caption">
                {{ tag.acf.title }}
              </router-link>
            </Viewport>
          </div>
          <Share v-if="portrait" />
          <div class="Article__cover"><Cover :caption="true" :cover="data.article.acf.content.cover" /></div>
        </div>
      </div>

      <div class="Article__flexible">
        <template v-for="(block, key) in data.article.acf.flexible">
          <div v-if="block.component == 'Cover'" class="Article__block-cover Page__container is-small Component">
            <div class="Article__block-cover-content">
              <Cover :caption="true" :cover="block.cover" />
            </div>
          </div>
          <Quote v-if="block.component == 'Quote'" :data="block" />
          <Gallery v-if="block.component == 'Gallery'" :data="block.gallery" />
          <BlockFlexible :key="block.text" v-if="block.component == 'BlockFlexible'" :data="block" />
        </template>
      </div>

    </template>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon'
import { computed, ref, watch } from '@vue/composition-api'
import { useQuery } from 'villus'
import { format, parseISO } from 'date-fns'
import Hashids from 'hashids'
import BtnQuery from '@/components/btn/BtnQuery'
import Quote from '@/components/quote/Quote'
import Gallery from '@/components/gallery/Gallery'
import Cover from '@/components/cover/Cover'
import BlockFlexible from '@/components/block-flexible/BlockFlexible'
import Share from '@/components/share/Share'

export default {
  name: 'Article',
  components: { Icon, Cover, Quote, Gallery, BlockFlexible, Share },
  setup(props, { root }) {
    const route = root.$store.getters['app/route']
    const fromParams = route.from && route.from.meta.name == 'articles' ? route.from.params : {}
    const { route: { meta: { id: articlesID } } } = root.$router.resolve({ name: 'articles.' + root.lang })
    const force = ref(null)

    const query = computed(() => {
      const q = `{
        page(id: ${articlesID}, idType: DATABASE_ID) { acf: acf_articles { intro { ${root.af('title', force.value)} } } }
        article(id: ${root.$route.params.id}, idType: DATABASE_ID) {
          date
          tags {
            nodes {
              ... on Tag { databaseId acf { ${root.af('title', force.value)} } }
            }
          }
          acf {
            content { ${root.af('title', force.value)} cover { title sourceUrl(size: LARGE) } }
            lang
            flexible {
              ... on Article_Acf_Flexible_Quote { fieldGroupName ${root.af('author', force.value)} ${root.af('text', force.value)} }
              ... on Article_Acf_Flexible_Cover { fieldGroupName cover { title sourceUrl(size: LARGE) } }
              ... on Article_Acf_Flexible_Gallery { fieldGroupName gallery { title sourceUrl(size: LARGE) } }
              ... on Article_Acf_Flexible_BlockFlexible {
                ${BtnQuery(root, force.value)}
                coverPosition
                fieldGroupName
                hasCover
                hasTitle
                ${root.af('text', force.value)}
                ${root.af('title', force.value)}
                cover { title sourceUrl(size: LARGE) }
              }
            }
          }
        }

      }`
      return q
    })

    const { data, error, done } = useQuery({ query })

    // ------------------------

    const hashids = new Hashids()
    const getTagParams = id => {
      return { category_id: '_', tags_ids: hashids.encode([id]) }
    }

    // ------------------------

    watch(data, () => {
      if(!data.value) return
      const d = parseISO(data.value.article.date)
      data.value.article['_date'] = { part1: format(d, '(dd)'), part2: format(d, 'MM.yyyy') }
      _.each(data.value.article.acf.flexible, block => {
        root.$set(block, 'component', block.fieldGroupName.replace('Article_Acf_Flexible_', ''))
      })
      force.value = data.value.article.acf.lang == 'all' ? null : data.value.article.acf.lang
    })

    return { data, route, fromParams, getTagParams, force }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Article
  padding .1px 0

  &__block-cover
    &-content
      position relative
      mq(height 600 200)

  &__header
    mq(margin-top 100 50)

  &__tags
    display flex
    flex-wrap wrap
    mq(margin-top 45 30)

  &__tag
    border-width 1px
    border-style solid
    transform scale(.9)
    opacity 0
    transition opacity 1s, transform 1s
    padding pxl(10) pxl(10) pxl(8)
    mq(
      margin-right 10 10,
      margin-bottom 10 10
    )
    +portrait()
      padding pxp(10) pxp(10) pxp(8)
    &.is-in-viewport
      transform translateX(0)
      opacity 1

  &__top
    transform translateX(pxl(20))
    opacity 0
    transition opacity 1s, transform 1s
    span
      margin-left pxl(79)
    &.is-in-viewport
      transform translateX(0)
      opacity 1

  &__title
    mq(
      width 845 100%,
      margin-top 15 20
    )


  &__cover
    position relative
    mq(
      height 638 200,
      margin-top 80 40
    )

  &__warning
    color: colors.red
    mq(
      margin-top 180 160,
      margin-bottom 40 40
    )

  &__back
    display flex
    align-items center
    position relative
    transform translateX(pxl(-20))
    opacity 0
    transition opacity 1s, transform 1s
    +portrait()
      transform translateX(0)
    mq(
      left -35 15
    )
    &.is-in-viewport
      transform translateX(0)
      opacity 1
    +landscape()
      &:hover
        .Icon
          transform translateX(pxl(-10)) scaleX(-1)
    svg
      +portrait()
        margin-left pxp(15)
    .Icon
      position relative
      transform scaleX(-1)
      left pxl(-10)
      top pxl(-1)
      transition transform .5s
      +portrait()
        left 0



</style>
