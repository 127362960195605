<template>
  <div class="PrevNext">
    <template v-if="entries.prev && entries.next">

      <router-link
        :to="{ name: 'creation.' + lang, params: { id: entries[direction].databaseId, slug: kebab(entries[direction].acf.content.title)  }}"
        :key="entries[direction].databaseId"
        v-for="direction in ['prev', 'next']"
        class="PrevNext__block"
        :class="'is-' + direction">
        <template v-if="landscape">
          <Cover :cover="entries[direction].acf.content.cover" />
          <div class="PrevNext__gradient" />
        </template>
        <div class="PrevNext__content">
          <Viewport>
            <div class="caption" :class="'PrevNext__from-' + direction" v-html="ui[direction]" />
          </Viewport>
          <SplitText type="line">
            <h2 class="h2">{{ entries[direction].acf.content.title }}</h2>
          </SplitText>
          <Viewport>
            <div :class="'PrevNext__from-' + direction">
              <Icon type="arrow" />
            </div>
          </Viewport>
        </div>
      </router-link>

    </template>
  </div>
</template>

<script>
import { computed, watch, ref } from '@vue/composition-api'
import { useQuery } from 'villus'
import Icon from '@/components/icon/Icon'
import Cover from '@/components/cover/Cover'

export default {
  name: 'PrevNext',
  components: { Cover, Icon },
  setup(props, { root }) {
    // creation(id: ${root.$route.params.id}, idType: DATABASE_ID) {
    //   prev {  }
    //   next { databaseId acf { content { ${root.af('title')} cover { sourceUrl(size: MEDIUM) } } } }
    // }
    const cursor = ref(null)
    const entries = ref({ prev: null, next: null })

    const { data, error, done } = useQuery({
      query: computed(() => {
        const q = `databaseId acf { content { ${root.af('title')} cover { sourceUrl(size: MEDIUM) } } }`
        return `{
            creations(where: {id: ${root.$route.params.id} orderby: {field: MENU_ORDER, order: ASC}}) {
              nodes { title }
              pageInfo { endCursor }
            }
            next: creations(after: "${cursor.value}", first: 1, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
              nodes { title ${q} }
            }
            prev: creations(after: "${cursor.value}", first: 1, where: {orderby: {field: MENU_ORDER, order: DESC}}) {
              nodes { title ${q} }
            }
						first: creations(first: 1 where: {orderby: {field: MENU_ORDER, order: ASC}}) {
							nodes { title ${q} }
						}
						last: creations(first: 1 where: {orderby: {field: MENU_ORDER, order: DESC}}) {
							nodes { title ${q} }
						}
          }
        `
      })
    })

    watch(data, () => {
      if(!data.value) return
      cursor.value = data.value.creations.pageInfo.endCursor
      entries.value.prev = data.value.prev.nodes[0]
      entries.value.next = data.value.next.nodes[0]
      if(!entries.value.prev)
        entries.value.prev = data.value.last.nodes[0]
      if(!entries.value.next)
        entries.value.next = data.value.first.nodes[0]
    })

    return { data, entries }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.PrevNext
  display flex
  +portrait()
    flex-direction column
    margin-bottom pxp(100)

  &__from-prev
    transform translateX(pxl(-20))

  &__from-next
    transform translateX(pxl(20))

  &__from-prev
  &__from-next
    opacity 0
    transition opacity 1s, transform 1s
    &.is-in-viewport
      opacity 1
      transform translateX(0px)

  h2.h2
    margin pxl(20) 0
    +portrait()
      margin pxp(20) 0
      text-align center

  &__content
    position relative
    +portrait()
      display flex
      justify-content center
      align-items center
      flex-direction column

  &__gradient
    position absolute
    left 0
    right 0
    bottom 0
    top 0
    background linear-gradient(to left, rgba(colors.grey.dark, 0), rgba(colors.grey.dark, 1))

  &__block
    width 50%
    position relative
    padding pxl(150)
    box-sizing border-box
    mq(width 50% 100%)
    +portrait()
      padding: pxp(80) gutter.portrait
    &.is-prev
      .PrevNext__gradient
      svg
        transform scaleX(-1)
    +landscape()
      .Cover
        opacity 0
        transition opacity 1s
      .Icon
        transition transform 1s
      &:hover
        .Cover
          opacity 1
        .PrevNext__from-prev
          .Icon
            transform translateX(pxl(-20))
        .PrevNext__from-next
          .Icon
            transform translateX(pxl(20))




</style>
