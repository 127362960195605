<script>
import { computed, watch, ref } from '@vue/composition-api'
import { useQuery } from 'villus'
import Settings from '@/config/Settings'

export default {
  name: 'Seo',
  render: h => h(),
  metaInfo() {
    return { title: this.title }
  },
  setup(props, { root }) {
    const title = ref('')
    const cpt = computed(() => root.$route.meta.name == 'creation' || root.$route.meta.name == 'article')
    const type = computed(() => cpt.value ? root.$route.meta.name : 'page')
    const id = computed(() => root.$route[cpt.value ? 'params' : 'meta'].id)
    const { data, error, done } = useQuery({
      query: computed(() => `{
        general { acf { seo { ${root.af('title')} } }}
        ${type.value}(id: ${id.value} idType: DATABASE_ID) { acf { seo { ${root.af('title')} } } }
      }`)
    })

    watch(data, () => {
      if(!data.value) return
      title.value = data.value[type.value].acf.seo.title || data.value.general.acf.seo.title
    })
    return { title }
  }
}
</script>
