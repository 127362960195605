const Settings = {
  author: 'Gaida&Steph',
  name: 'Origin',
  cms: 'wordpress' // 'wordpress' || 'craft'
}

if(!Settings.cms)
  Settings['locale'] =  ['fr', 'en']

module.exports = Settings

