<template>
  <transition appear name="Search">
    <div class="Search">
      <div class="Search__close" @click="$store.commit('app/SET_SEARCH', false)">
        <svg viewBox="0 0 25 19.1">
          <path d="M20.8,0l0.7,0.7L13.2,9l9.3,9.3L21.8,19l-9.4-9.2l-9.3,9.3l-0.7-0.7L11.8,9L3.4,0.7L4.1,0l8.3,8.3L20.8,0z"/>
        </svg>
      </div>

      <div class="Search__overflow" ref="overflow">
        <div class="Search__content">

          <div class="Page__container">
            <InputText @update="model = $event" :text="ui.search" />

            <div class="Search__result">
              <transition-group name="Search__link">
                <SplitText type="line" v-for="creation in results" :key="creation.acf.content.title">
                  <router-link
                    @click.native="$store.commit('app/SET_SEARCH', false)"
                    class="Search__link h1-alt"
                    :to="{ name: 'creation.' + lang, params: { id: creation.databaseId, slug: kebab(creation.acf.content.title) } }"
                    >
                    {{ creation.acf.content.title }}
                  </router-link>
                </SplitText>
              </transition-group>
            </div>
          </div>

        </div>
      </div>

    </div>
  </transition>
</template>

<script>
import InputText from '@/components/input-text/InputText'
import { ref, watch, computed, onMounted, onUnmounted } from '@vue/composition-api'
import { setContainer, resetContainer } from '@/components/scroll/Scroll'
import { useQuery } from 'villus'

export default {
  name: 'Search',
  components: { InputText },
  setup(props, { root, refs }) {

    // ----------------------------

    onMounted(() => {
      refs.overflow.querySelector('input').focus()
      setContainer(refs.overflow)
    })
    onUnmounted(() => resetContainer())
    const model = ref(null)

    // ----------------------------

    const creations = ref(null)

    const { data, error, done } = useQuery({
      query: computed(() => `{
        creations(first: 999) { nodes { databaseId acf { content { ${root.af('title')} cover { sourceUrl(size: MEDIUM) } } } } }
      }`)
    })

    watch(data, () => {
      if(!data.value) return
      creations.value = data.value.creations.nodes
      _.each(creations.value, c => {
        if(c.acf.content.title)
          root.$set(c.acf.content, 'normalized', c.acf.content.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
      })
    })

    // ----------------------------

    const results = ref(null)

    watch(model, () => {
      if(!model.value) return results.value = null
      results.value = _.filter(creations.value, c => {
        if(c.acf.content.title)
          return c.acf.content.title.toLowerCase().search(model.value) > -1 || c.acf.content.normalized.toLowerCase().search(model.value) > -1
      })
    })

    // ----------------------------

    const route = computed(() => root.$route.path)
    watch(route, (nv, ov) => {
      if(!ov) return
      root.$store.commit('app/SET_SEARCH', false)
    })

    return { model, results }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'
@import '~@/commons/stylus/Style'

.Search
  position fixed
  z-index 10
  top 0
  left 0
  height 100%
  width 100%
  background: colors.grey.dark
  transition transform 1.2s cubic-bezier(0.16, 1, 0.3, 1)
  &-enter
  &-leave-active
    transform translateY(100%)

  &__overflow
    height 100%
    overflow-y auto
    width 100%
    scrollbar-width none
    -ms-overflow-style none
    &::-webkit-scrollbar
      width 0
      height 0
    .Page__container
      flex-direction column
      display flex
      justify-content center

  &__content
    padding pxl(200) 0

  &__result
    display flex
    flex-direction column
    margin-top pxl(50)

  &__link
    margin-bottom pxl(20)
    transition opacity .1s
    display block
    &:last-child
      margin-bottom pxl(0)
    &-enter
    &-leave-active
      opacity 0

  &__close
    width pxl(40)
    height pxl(40)
    position absolute
    right gutter.landscape
    top gutter.landscape
    cursor pointer
    z-index 9

  .InputText
    position relative
    width 100%
    transform translate3d(0, 0, 0)
    &.is-active
      .InputText__label
        transform translateY(0px) scale(.2)

  .InputText__label
  .InputText__field
    transition opacity .5s, transform .25s
    h1-alt()

  .InputText__field
    border-bottom: 1px solid colors.white

</style>
