<template>
  <div class="Home">
    <Intro />
    <template v-if="data">
      <div class="Page__container is-small Component">
        <Parallax :y="100">
          <SplitText type="line">
            <h2 class="h2-alt" v-html="data.page.acf.intro.text" />
          </SplitText>
        </Parallax>
      </div>
    </template>
    <Categories />
    <HomeRobertLepage />
    <LatestArticles />
    <!--Instagram /-->
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useQuery } from 'villus'
import Intro from '@/components/intro/Intro'
import Categories from '@/components/categories/Categories'
import LatestArticles from '@/components/latest-articles/LatestArticles'
import HomeRobertLepage from './HomeRobertLepage'
import Instagram from '@/components/instagram/Instagram'

export default {
  name: 'home',
  components: { Intro, Categories, HomeRobertLepage, LatestArticles, Instagram },
  setup(props, { root }) {
    const { data, error, done } = useQuery({
      query: computed(() => `{
        page(id: ${root.$route.meta.id}, idType: DATABASE_ID) { acf { intro { ${root.af('text')} } } }
      }`)
    })

    return { data }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Home
  display block

  .Component.is-light.LatestArticles
    padding-top: gutter.component.landscape

</style>
