<template>
  <div class="Articles">

    <div class="is-light" v-if="data">

      <IntroText :data="{
        title: data.page.acf.intro.title,
        cover: first ? first.acf.content.cover : null
      }">
        <ArticlePreview v-if="first" :article="first" :hoverCover="false" />
      </IntroText>

      <Filters />

      <div class="Articles__content Page__container is-small">
        <div class="Articles__blocks">
          <ArticlePreview
            v-for="(article, index) in articles"
            :key="article.databaseId"
            :article="article"
          />
        </div>
        <BtnMore @click="next = pageInfo.endCursor" v-if="pageInfo && pageInfo.hasNextPage" />
      </div>

    </div>

  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from '@vue/composition-api'
import { useQuery } from 'villus'
import Filters from '@/components/filters/Filters'
import IntroText from '@/components/intro-text/IntroText'
import ArticlePreview from '@/components/article-preview/ArticlePreview'
import BtnMore from '@/components/btn-more/BtnMore'
import Hashids from 'hashids'

export default {
  name: 'Articles',
  components: { Filters, IntroText, ArticlePreview, BtnMore },
  setup(props, { root }) {
    const hashids = new Hashids()
    const pageInfo = ref({})
    const next = ref('')
    const route = computed(() => root.$route)
    const first = ref(null)
    const query = computed(() => {
      let tax = `taxQuery: { relation: AND taxArray: [`
      tax += `{ taxonomy: CCATEGORY, operator: AND, field: ID, terms: "${root.$route.params.category_id}" }`
      _.each(hashids.decode(root.$route.params.tags_ids), tag => tax += `{ taxonomy: TAG, operator: AND, field: ID, terms: "${tag}" }`)
      tax += `]}`

      let articles = ''
      if(first.value)
        articles = `
          articles(
            before: "${next.value}"
            first: 12
            where: {
              notIn: ${first.value.databaseId}
              ${tax}
              orderby: {field: DATE, order: DESC}
            }
          ) {
            nodes { date databaseId acf { isExternal ${root.af('externalUrl')} content { ${root.af('title')} cover { sourceUrl(size: MEDIUM) } } } }
            pageInfo { hasNextPage endCursor }
          }
        `

      return `
        {
          page(id: ${root.$route.meta.id}, idType: DATABASE_ID) { acf: acf_articles { intro { ${root.af('title')} } } }
          first: articles(first: 1, where: { orderby: {field: DATE, order: DESC} }) {
            nodes { date databaseId acf { isExternal ${root.af('externalUrl')} content { ${root.af('title')} cover { title sourceUrl(size: MEDIUM) } } } }
          }
          ${articles}
        }
      `
    })

    const { data, error, done, execute } = useQuery({ query: query })

    // -----

    const articles = ref([])

    watch(data, () => {
      if(!data.value) return

      if(data.value.articles) {
        articles.value = [...articles.value, ...data.value.articles.nodes]
        pageInfo.value = data.value.articles.pageInfo
      }

      first.value = data.value.first.nodes[0]
    })


    watch(route, () => {
      articles.value = []
      pageInfo.value = null
      next.value = ''
    })

    return { data, first, articles, pageInfo, next }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Articles

  &__content
    padding-bottom: gutter.component.landscape
    +portrait()
      padding-bottom: gutter.component.portrait

  &__blocks
    display flex
    flex-wrap wrap
    mq(margin-top 80 120)

  .ArticlePreview
    mq(
      margin-bottom 100 80,
      margin-right 89 0
    )
    +portrait()
      width 100%
    &:nth-child(3n)
      margin-right 0

  .IntroText
    padding-top: gutter.component.landscape
    +portrait()
      padding-top: gutter.component.portrait
    .highlight
      text-align center
    background: colors.grey.dark
    color: colors.white
    &__highlight
      +landscape()
        padding-top pxl(200)
    &__text
      +portrait()
        margin-left 0
    &__content
      align-items center
      mq(margin-top 150 170)
    &__cover
      mq(
        height 750 400,
        margin-bottom -65 -20
      )
      +portrait()
        width 100%
    .ArticlePreview
      margin-right 0
      width 100%
      svg
        fill white
      &__title
        .h4
          mq(
            font-size 50 37,
            line-height 50 37
          )


</style>
