<template>
  <div class="ExMachina">
    <Intro />

    <template v-if="data">
      <div class="Page__container is-small Component">
        <SplitText type="line">
          <h2 class="h2-alt" v-html="data.page.acf.intro.intro" />
        </SplitText>
      </div>

      <div class="Page__container ExMachina__intro">
        <BlockFlexible :data="{
          text: data.page.acf.intro.text,
          textColumn: true,
          sections: data.page.acf.intro.label
        }" />
      </div>

      <div class="ExMachina__cover">
        <CoverGradient direction="to-top" :cover="data.page.acf.content.cover" />
      </div>

      <div class="Component is-light">
        <BlockFlexible :data="{
          title: data.page.acf.content.title,
          hasTitle: true,
          text: data.page.acf.content.text,
          sections: data.page.acf.content.label,
          isWysiwyg: false,
          textColumn: true
        }" />
      </div>

      <div class="Component is-light">
        <Publications v-if="data.page.acf.publications" :publications="data.page.acf.publications" />
      </div>

      <Gallery v-if="data.page.acf.gallery" :data="data.page.acf.gallery" />

      <div class="ExMachina__studio Component is-light">
        <BlockFlexible :data="{
          hasTitle: true,
          titleClass: 'h2',
          title: data.page.acf.studio.title,
          text: data.page.acf.studio.text,
          btn: data.page.acf.studio.btn,
          textColumn: true
        }" />
        <div class="Page__container is-small ExMachina__studio-cover">
          <Cover :caption="true" :cover="data.page.acf.studio.cover" />
        </div>
      </div>

      <div class="Component is-light">
        <Cta :data="data.page.acf.cta" />
      </div>

      <Partners :data="data.page.acf.partners" />

    </template>

  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useQuery } from 'villus'
import Intro from '@/components/intro/Intro'
import BlockFlexible from '@/components/block-flexible/BlockFlexible'
import Publications from '@/components/publications/Publications'
import CoverGradient from '@/components/cover-gradient/CoverGradient'
import Gallery from '@/components/gallery/Gallery'
import Cover from '@/components/cover/Cover'
import BtnQuery from '@/components/btn/BtnQuery'
import Cta from '@/components/cta/Cta'
import CtaQuery from '@/components/cta/CtaQuery'
import Partners from '@/components/partners/Partners'

export default {
  name: 'ExMachina',
  components: { Intro, BlockFlexible, Publications, CoverGradient, Gallery, Cover, Cta, Partners },
  setup(props, { root }) {
    const { data, error, done } = useQuery({
      query: computed(() => ` {
          page(id: ${root.$route.meta.id}, idType: DATABASE_ID) { acf: acf_ex_machina {
            intro { ${root.af('intro')} ${root.af('label')} ${root.af('text')} }
            publications { ${root.af('title')} url }
            content { ${root.af('text')} ${root.af('title')} ${root.af('label')} cover { title sourceUrl(size: LARGE) } }
            gallery { title sourceUrl(size: LARGE) }
            studio { ${root.af('text')} ${root.af('title')} ${BtnQuery(root)} cover { title sourceUrl(size: LARGE)  } }
            ${CtaQuery(root)}
            partners { ... on Page_AcfExMachina_Partners_Logos { ${root.af('label')} logos { url logo { sourceUrl(size: LARGE) } } } }
          }}
        }
      `)
    })

    return { data }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.ExMachina

  &__cover
    position relative
    mq(height 800 317)

  &__studio
    padding: gutter.component.landscape 0
    +portrait()
      padding: gutter.component.portrait 0
    &-cover
      position relative
      mq(
        height 598 188,
        margin-top 60 40
      )

</style>
