<template>
  <div class="Team">
    <Intro />

    <template v-if="data">
      <div class="Team__content Component">
        <BlockFlexible :data="{
          textColumn: true,
          title: data.page.acf.content.title,
          text: data.page.acf.content.text,
          sections: data.page.acf.content.label,
        }" />
      </div>

      <ProfilRepeater :data="data.page.acf.exMachina" />

      <div class="Component is-light">
        <ProfilRepeater :data="data.page.acf.robertLepage" />
      </div>

      <Career v-if="data.page.acf.career.list" :data="data.page.acf.career" />
      <Cta :data="data.page.acf.cta" />
    </template>

  </div>
</template>

<script>
import Intro from '@/components/intro/Intro'
import BlockFlexible from '@/components/block-flexible/BlockFlexible'
import { computed } from '@vue/composition-api'
import { useQuery } from 'villus'
import ProfilRepeater from '@/components/profil-repeater/ProfilRepeater'
import Career from '@/components/career/Career'
import Cta from '@/components/cta/Cta'
import CtaQuery from '@/components/cta/CtaQuery'

export default {
  name: 'Team',
  components: { Intro, BlockFlexible, ProfilRepeater, Career, Cta },
  setup(props, { root }) {
    const { data, error, done } = useQuery({
      query: computed(() => `
        {
          site(handle: "${root.lang}")
          page(id: ${root.$route.meta.id}, idType: DATABASE_ID) { acf: acf_team {
            content { ${root.af('label')} ${root.af('title')} ${root.af('text')}  }
            exMachina { ${root.af('label')} list { email instagram linkedin name ${root.af('title')} cover { title sourceUrl(size: LARGE) } } }
            robertLepage { ${root.af('label')}  list { email instagram linkedin name ${root.af('title')} cover { title sourceUrl(size: LARGE) } } }
            career { ${root.af('title')} ${root.af('label')} list { city ${root.af('position')} ${root.af('title')} file { mediaItemUrl } } }
            ${CtaQuery(root)}
          }}
        }
      `)
    })

    return { data }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Team
  display block

  .Cta__sections
    +portrait()
      max-width pxp(150)


</style>
