import BtnQuery from '@/components/btn/BtnQuery'

export default root => `
  cta {
    ${root.af('title')}
    ${root.af('label')}
    hasLabel
    ${BtnQuery(root)}
  }
`
