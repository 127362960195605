<template>
  <div class="Gallery Component">
    <div class="Gallery__content">

      <Viewport rootMargin="0% 0% -50%">
        <div class="Gallery__band" />
      </Viewport>
      <Section :text="ui.gallery" />

      <div class="Gallery__slider-wrapper">

        <div class="swiper-container Gallery__container" ref="swiper">
          <div class="swiper-wrapper Gallery__wrapper">
            <div class="swiper-slide Gallery__slide" v-if="data" v-for="slide in data">
              <Cover :appear="false" :caption="true" :lazy="false" :cover="slide" />
            </div>
          </div>
          <div class="Gallery__control">
            <div class="Gallery__btn is-prev" ref="prev"><Icon type="circleArrow" /></div>
            <div class="Gallery__btn is-next" ref="next"><Icon type="circleArrow" /></div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import Swiper from 'swiper/js/swiper.min.js'
import Cover from '@/components/cover/Cover'
import Icon from '@/components/icon/Icon'
require('swiper/css/swiper.css')

export default {
  name: 'Gallery',
  components: { Cover, Icon },
  props: {
    data: { default: null }
  },
  setup(props, { refs, root }) {

    onMounted(() => {
      const swiper = new Swiper(refs.swiper, {
        centeredSlides: true,
        speed: 800,
        loop: true,
        slidesPerView: root.landscape ? 'auto' : 1,
        navigation: {
          nextEl: refs.next,
          prevEl: refs.prev,
          disabledClass: 'is-disabled'
        },
        effect: root.landscape ? 'coverflow' : 'slide',
        coverflowEffect: {
          rotate: 0,
          depth: 200,
          stretch: -100,
          slideShadows: false,
        }
      })
    })

  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Gallery

  .Section
    z-index 9
    &__text
      transform translateX(0%)

  &__control
    position absolute
    top 50%
    transform translateY(-50%)
    z-index 9
    width 100%
    left 0

  &__btn
    position absolute
    cursor pointer
    transition opacity .1s
    &.is-next
      mq(right 109 10)
    &.is-prev
      mq(left 136 10)
      transform scaleX(-1)
    &.is-disabled
      opacity 0
      pointer-events none

  &__container
    height 100%
    +portrait()
      margin 0 pxp(20)

  &__slide
    height 100%
    mq(
      width 1065 100%,
      margin-right 35 0
    )

  &__content
    position relative
    padding .1px 0

  .Section
    top: gutter.component.landscape
    left: gutter.landscape
    +portrait()
      margin-left: gutter.portrait

  &__band
    position absolute
    width pxl(812)
    background: colors.black
    top pxl(0)
    bottom 0px
    transform scaleY(0)
    transition transform 1s
    transform-origin top center
    &.is-in-viewport
      transform scaleY(1)
    +portrait()
      height pxp(240)
      width 100%

  &__slider-wrapper
    position relative
    margin-top: gutter.component.landscape
    mq(
      height 687 217,
      margin-bottom 120 60
    )

</style>

