<template>
  <div class="Career Component">

    <div class="Page__container is-small">
      <Section v-if="portrait" :text="data.label" />
      <SplitText type="line">
        <h2 class="h2" v-html="data.title" />
      </SplitText>
    </div>

    <div class="Page__container">
      <Section v-if="landscape" :text="data.label" />
      <div class="Page__container is-small">
        <div class="Career__blocks">
          <Viewport :key="index" v-for="(job, index) in data.list">
            <a
              @mouseenter="showCursor('show', 'white')"
              @mouseleave="hideCursor"
              :href="job.file ? job.file.mediaItemUrl : null"
              target="_blank"
              class="Career__block Viewport__scalefade">
              <div class="Career__left">
                <div class="h5" v-html="job.title" />
                <div class="h5 Career__sep" v-html="'|'" />
                <div class="body1" v-html="job.city" />
              </div>
              <div class="subtitle1" v-html="job.position" />
              <CirclePlus v-if="portrait" />
            </a>
          </Viewport>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import CirclePlus from '@/components/circle-plus/CirclePlus'
export default {
  name: 'Career',
  components: { CirclePlus },
  props: {
    data: { default: null }
  }

}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Career

  .CirclePlus
    position absolute
    right 0
    bottom pxp(20)

  &__sep
    margin 0 pxl(10)
    +portrait()
      display none

  &__left
    display flex
    +landscape()
      align-items center
    +portrait()
      flex-direction column
      margin-bottom pxp(30)

  &__blocks
    mq(margin-top 80 80)

  &__block
    border-top: 1px solid colors.white
    padding pxl(30) 0
    display flex
    justify-content space-between
    align-items center
    +portrait()
      padding pxp(30) 0
      flex-direction column
      justify-content flex-start
      align-items flex-start
    &:last-child
      border-bottom: 1px solid colors.white


</style>
