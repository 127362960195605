<template>
  <div class="Cursor button2" ref="el">
    <transition name="Cursor__content">
      <div v-if="cursor && landscape" class="Cursor__content">
        <BtnCircle :color="cursor.color" :text="ui[cursor.type]" />
      </div>
    </transition>
  </div>
</template>

<script>
import { onMounted, onUnmounted, computed } from '@vue/composition-api'
import { gsap } from 'gsap'
import BtnCircle from '@/components/btn-circle/BtnCircle'

export default {
  name: 'CursorComp',
  components: { BtnCircle },
  setup(props, { root, refs }) {
    const cursor = computed(() => root.$store.getters['app/cursor'])
    const sync = e => gsap.to(refs.el, { x: e.x, y: e.y })

    onMounted(() => window.addEventListener('mousemove', sync))
    onUnmounted(() => window.removeEventListener('mousemove', sync))

    return { cursor }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Cursor
  position fixed
  z-index 10
  pointer-events none

  &__content
    position absolute
    top 50%
    transform translate(-50%, -50%)
    transition opacity 1s
    color white
    &-enter
    &-leave-active
      opacity 0

</style>
