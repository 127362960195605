import _ from 'lodash'
import Settings from '@/config/Settings'
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

export const getRouter = async () => {

  let routes = window.ROUTES

  // ---------------------

  if(Settings.cms) {
    if(!routes) {
      const res = await fetch('/get_vue_routes')
      routes = await res.json()
    }
    _.each(routes, r => r.component = require('@/pages/' + r.component).default)
  }

  // ---------------------

  // redirection sur la home (lang detect) + 404
  routes.push({ path: '/', redirect: { name: 'home.' + document.documentElement.lang }})
  routes.push({ path: '*', redirect: { name: 'home.' + document.documentElement.lang }})

  // -----------------------

  const router = new VueRouter({ mode: 'history', routes: routes })

  router.beforeEach((to, redirect, next) => {
    router.app.$store.commit('app/SET_FROM_ROUTE', router.app.$route)
    next()
  })

  router.afterEach(to => {
    router.app.$store.commit('app/SET_TO_ROUTE', to)
    if(router.app.lang != to.meta.lang)
      router.app.$store.commit('app/LANG_CHANGED', to.meta.lang)
    if(router.app.setLangRouteNames)
      router.app.setLangRouteNames()
  })

  return router
}
