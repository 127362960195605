<template>
  <div>
    <template v-if="data">
      <div class="Teaser Component" v-if="hasVideo || data.creation.acf.teaser.cover">

        <div class="Teaser__video" :class="{ 'has-cover': data.creation.acf.teaser.cover }" ref="wrapper">
          <div
            v-if="data.creation.acf.teaser.url"
            ref="video"
            data-plyr-provider="youtube"
            :data-plyr-embed-id="data.creation.acf.teaser.url"
          />
        </div>

        <div ref="cover" class="Teaser__cover">
          <Cover :caption="true" :cover="data.creation.acf.teaser.cover" />
          <div class="Teaser__catcher"
            v-if="landscape && hasVideo"
            @click="play"
            @mouseenter="showCursor('play', 'white')"
            @mouseleave="hideCursor"
          />

          <div class="Teaser__btn" @click="play" v-if="portrait && hasVideo">
            <BtnCircle color="white" :text="ui.play" />
          </div>
        </div>

      </div>
    </template>
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from '@vue/composition-api'
import { useQuery } from 'villus'
import Cover from '@/components/cover/Cover'
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'
import { gsap } from 'gsap'
import BtnCircle from '@/components/btn-circle/BtnCircle'

export default {
  name: 'Credits',
  components: { Cover, BtnCircle },
  setup(props, { root, refs }) {
    const { data, error, done } = useQuery({
      query: computed(() => {
        const q = `{
          creation(id: ${root.$route.params.id}, idType: DATABASE_ID) {
            acf {
              teaser {
                ${root.af('url')}
                cover { title sourceUrl(size: LARGE) }
              }
            }
          }
        }`
        return q
      })
    })


    // ----------------

    let player = null
    const hasVideo = ref(false)
    const play = () => {
      player.play()
      gsap.to(refs.cover, { autoAlpha: 0 })
      gsap.to(refs.wrapper, { autoAlpha: 1 })
    }

    watch(data, () => {
      if(!data.value) return
      if(data.value.creation.acf.teaser.url)
        hasVideo.value = true
    })

    onMounted(() => {
      watch(data, () => {
        if(!data.value) return
        player = new Plyr(refs.video, {
          controls: ['play', 'progress', 'current-time', 'fullscreen']
        })
      })
    })

    return { play, data, hasVideo }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

:root
  --plyr-color-main: colors.red

.Teaser
  &.Component
    margin-top 0
  position relative
  +portrait()
    padding-bottom: 56.25%
  +landscape()
    height:  100vh

  .plyr
    +landscape()
      height: 100%

  &__btn
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)

  &__video
    &.has-cover
      opacity 0

  &__video
  &__catcher
  &__cover
    position absolute
    top 0
    left 0
    height 100%
    width 100%

  &__catcher
    cursor pointer

</style>

