<template>
  <div class="InputText" :class="{'is-active': model != '' || focus, 'is-error': error}">
    <label class="InputText__label body1">
      {{ text }}
    </label>
    <input
      @input="$emit('update', model)"
      @blur="focus = false"
      @focus="focus = true"
      class="InputText__field body1"
      v-model="model"
      type="text"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { gsap } from 'gsap'

export default {
  name: 'InputText',
  props: {
    text: { default: '' },
    error: { default: false }
  },
  setup(props, { refs }) {
    const model = ref('')
    const focus = ref(false)
    return { model, focus }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.InputText
  position relative
  width 100%
  &.is-active
    .InputText__label
      transform translateY(0px) scale(.6)
  &.is-error
    .InputText__label
      color: colors.red

  &__label
    position absolute
    top 0
    transform-origin left top
    transform translateY(pxl(20))
    transition opacity .5s, transform .5s, color .5s
    pointer-events none
    text-transform lowercase
    +portrait()
      transform translateY(pxp(15))

  &__field
    padding pxl(20) 0 pxl(10)
    +portrait()
      padding pxp(15) 0
    width 100%
    mq(
      font-size 22 14
    )

</style>

