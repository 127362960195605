// vue
import Vue from 'vue'
Vue.config.productionTip = false

import App from './App.vue'
import store from '@/store/Store'

// core
import { getRouter } from '@/core/js/Route.js'
import Settings from '@/config/Settings'
import '@/core/js/Copyright'

import TranslationMixin from '@/core/mixins/Translation'
Vue.mixin(TranslationMixin)

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

import CommonsMixin from '@/mixins/Commons'
Vue.mixin(CommonsMixin)

import VueKinesis from 'vue-kinesis'
Vue.use(VueKinesis)

// global components
Vue.component('Viewport', require('@/components/viewport/Viewport').default)
Vue.component('SplitText', require('@/components/split-text/SplitText').default)
Vue.component('Parallax', require('@/components/parallax/Parallax').default)
Vue.component('Section', require('@/components/section/Section').default)

// create vue
const init = async () => {
  const router = await getRouter()

  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#App')
}

init()
