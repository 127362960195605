<template>
  <div class="HomeRobertLepage Component is-light">
    <Viewport rootMargin="0% 0% -50%">
      <div class="HomeRobertLepage__band" />
    </Viewport>
    <BlockFlexible v-if="data" :data="{
      title: data.page.acf.robertLepage.title,
      text: data.page.acf.robertLepage.text,
      cover: data.page.acf.robertLepage.cover,
      btn: data.page.acf.robertLepage.btn,
      sections: data.page.acf.robertLepage.label,
      hasCover: true,
      hasTitle: true,
    }" />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useQuery } from 'villus'
import HomeRobertLepage from './HomeRobertLepage'
import BlockFlexible from '@/components/block-flexible/BlockFlexible'
import BtnQuery from '@/components/btn/BtnQuery'

export default {
  name: 'home',
  components: { BlockFlexible },
  setup(props, { root }) {
    const { data, error, done } = useQuery({
      query: computed(() => `{
          page(id: "${root.$route.meta.id}", idType: DATABASE_ID) {
            id
            acf {
              robertLepage {
                ${root.af('title')}
                ${root.af('text')}
                ${root.af('label')}
                cover { sourceUrl(size: LARGE) }
                ${BtnQuery(root)}
              }
            }
          }
        }
      `)
    })

    return { data }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'
@import '~@/commons/stylus/Style'

.HomeRobertLepage
  position relative

  .Cover__caption
    display none

  .Section
    color white

  .h3
    h2()

  &__band
    position absolute
    background: colors.black
    top 0
    transform scaleY(0)
    transition transform 1s
    transform-origin top center
    &.is-in-viewport
      transform scaleY(1)
    mq(
      width 372 100%,
      height 100% 312
    )


</style>
