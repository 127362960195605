<template>
  <div class="Instagram Component">
    <div class="Page__container">
      <Section text="INSTAGRAM" />
      <div class="Instagram__block" v-if="covers">
        <div class="Instagram__hover" @mouseenter="showCursor('explore')" @mouseleave="hideCursor">
          <transition name="Instagram__item">
            <a class="Instagram__item" :key="current" target="_blank" :href="covers[current].url">
              <Cover :lazy=false :appear="false" :cover="covers[current]" />
            </a>
          </transition>
          <BtnCircle v-if="portrait" :text="ui.explore" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, onUnmounted } from '@vue/composition-api'
import { useQuery } from 'villus'
import Cover from '@/components/cover/Cover'
import BtnCircle from '@/components/btn-circle/BtnCircle'

export default {
  name: 'Instagram',
  components: { Cover, BtnCircle },
  setup(props, { root }) {
    const { data, error, done } = useQuery({
      query: computed(() => `{
        general { acf { socials { url key } } }
      }`)
    })

    const covers = ref(null)

    watch(data, async () => {
      if(!data.value) return
      const instaInfos = _.find(data.value.general.acf.socials, d => d.key == 'instagram')
      if(instaInfos) {
        covers.value = await fetchInstagramPhotos(instaInfos.url)
      }
    })

    const fetchInstagramPhotos = async (accountUrl) => {
      const instagramRegExp = new RegExp(/<script type="text\/javascript">window\._sharedData = (.*);<\/script>/)
      const res = await fetch(accountUrl)
      const body = await res.text()
      const json = JSON.parse(body.match(instagramRegExp)[1])
      const edges = json.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges.splice(0, 4)
      return edges.map(({ node }) =>  ({
        url: `https://www.instagram.com/p/${node.shortcode}/`,
        sourceUrl: node.thumbnail_src,
        displayUrl: node.display_url,
        caption: node.edge_media_to_caption.edges[0].node.text
      }))
    }

    const current = ref(0)
    let interval = null

    onUnmounted(() => {
      clearInterval(interval)
    })

    watch(covers, () => {
      if(!covers.value) return
      interval = setInterval(() => {
        if(current.value == covers.value.length - 1)
          return current.value = 0
        current.value++
      }, 1500)
    })

    return { covers, current }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Instagram

  &__block
    justify-content center
    display flex
    mq(height 255 197)
    position relative

  &__hover
    position absolute
    .BtnCircle
      position absolute
      right 0%
      top 0%
      transform translate(50%, -50%)
    mq(
      width 255 197,
      height 255 197
    )

  &__item
    display block
    position absolute
    transition opacity 1s
    height 100%
    width 100%
    &-enter
    &-leave-active
      opacity 0


</style>
