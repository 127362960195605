<template>
  <Viewport>
    <div class="Wysiwyg">
      <div v-html="text" />
    </div>
  </Viewport>
</template>

<script>
export default {
  name: 'Wysiwyg',
  props: {
    text: { default: null }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'
@import '~@/commons/stylus/Style'

.Wysiwyg
  white-space pre-line
  opacity 0
  transform translateY(pxl(100))
  transition opacity 1s, transform 1s
  &.is-in-viewport
    transform translateY(pxl(0))
    opacity 1

  body1()

  h1
    h1()
    margin pxl(50) 0
  h2
    h2()
    margin pxl(25) 0
  h3
    h3()
    margin pxl(20) 0
  h4
    h4()
    margin pxl(15) 0
  h5
    h5()
    margin pxl(10) 0

  p
    margin pxl(25) 0

  a
    lien2()

  strong
  b
    subtitle2()

  i
  em
    font-style italic


  ol
  ul
    list-style-type disc
    margin pxl(20) 0
    list-style-position outside
    padding-left pxl(10)
    list-style none
    +portrait()
      margin pxp(20) 0
      padding-left pxp(5)
    li
      mq(
        margin 10 10,
        line-height 30 20
      )
      &:before
        position absolute
        content "•"
        color: colors.red
        mq(
          font-size 24 15,
          margin-left -20 -15
        )

  ol
    list-style-type decimal
    li
      &:before
        content ''


</style>
