var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"RobertLepage"},[(_vm.data)?[_c('div',{staticClass:"Component is-light"},[_c('IntroText',{attrs:{"data":{
        title: _vm.data.page.title,
        cover: _vm.data.page.acf.intro.cover
      }}},[_c('SplitText',{attrs:{"type":"line"}},[_c('h2',{staticClass:"h2",domProps:{"innerHTML":_vm._s(_vm.data.page.acf.intro.title)}})])],1)],1),_c('div',{staticClass:"RobertLepage__bio Component is-light"},[_c('BlockFlexible',{attrs:{"data":{
        textColumn: true,
        text: _vm.data.page.acf.intro.bio,
        sections: _vm.data.page.acf.intro.label,
        btn: _vm.data.page.acf.intro.btn
      }}})],1),_c('Gallery',{attrs:{"data":_vm.data.page.acf.gallery}}),_c('Publications',{attrs:{"publications":_vm.data.page.acf.publications}}),_c('Awards',{key:_vm.$route.meta.name + _vm.$route.params}),_c('div',{staticClass:"Component is-light"},[_c('Cta',{attrs:{"data":_vm.data.page.acf.cta}})],1),_c('LatestArticles')]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }