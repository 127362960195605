<template>
  <div class="Creations">
    <Intro />

    <Filters />

    <template v-if="data">
      <div class="Page__container is-small">
        <transition name="Creations__breadcrumb" mode="out-in">
          <div class="Creations__breadcrumb button2" v-if="data.terms">
            <span class="Creations__breadcrumb-nb">({{ data.creations.nodes.length }})</span>
            <span class="Creations__breadcrumb-name" v-for="term in data.terms.nodes" v-html="term.acf.title" />
          </div>
        </transition>
        <transition name="Creations__blocks" mode="out-in">
        <div class="Creations__blocks" :class="{ 'is-hover': over }" :key="$route.path">
            <router-link
              :to="{ name: 'creation.' + lang, params: { id: creation.databaseId, slug: kebab(creation.acf.content.title) } }"
              :key="index"
              class="Creations__block"
              v-for="(creation, index) in data.creations.nodes">

              <kinesis-container>
                <kinesis-element class="Creations__cover" v-if="creation.acf.content.cover && currentOver == index" :strength="10" type="depth">
                  <Cover :cover="creation.acf.content.cover" />
                </kinesis-element>

                <div class="Creations__title" @mouseenter="over = true; currentOver = index" @mouseleave="over = false; currentOver = null">
                  <SplitText renderMargin="-20% 0%">
                    <div class="Creations__index caption">{{ index < 10 ? '0' + (index + 1) : index + 1 }}</div>
                  </SplitText>
                  <SplitText type="line" renderMargin="-20% 0%">
                    <h1 class="h1-alt">{{ creation.acf.content.title }}</h1>
                  </SplitText>
                </div>
              </kinesis-container>

            </router-link>
          </div>
        </transition>
      </div>
    </template>

    <Calendars />

  </div>
</template>

<script>
import Intro from '@/components/intro/Intro'
import { computed, ref } from '@vue/composition-api'
import { useQuery } from 'villus'
import Filters from '@/components/filters/Filters'
import Calendars from '@/components/calendars/Calendars'
import Cover from '@/components/cover/Cover'
import Hashids from 'hashids'

export default {
  name: 'Creations',
  components: { Intro, Filters, Calendars, Cover },
  setup(props, { root }) {
    const hashids = new Hashids()
    const query = computed(() => {
      const tags_ids = hashids.decode(root.$route.params.tags_ids)
      const cat_id = root.$route.params.category_id == '_' ? null : root.$route.params.category_id
      let tax = `taxQuery: { relation: AND taxArray: [`
      tax += `{ taxonomy: CCATEGORY, operator: AND, field: ID, terms: "${cat_id}" }`
      _.each(tags_ids, tag => tax += `{ taxonomy: TAG, operator: AND, field: ID, terms: "${tag}" }`)
      tax += `]}`

      let terms = ''
      if(cat_id || tags_ids.length > 0)
        terms = `terms(where: {include: [${cat_id}, ${tags_ids}]}) {
          nodes { ... on Ccategory { acf { ${root.af('title')} } } ... on Tag { acf { ${root.af('title')} } } }
        }`

      const q =`{
        creations( first: 990 where: { ${tax} orderby: {field: MENU_ORDER, order: ASC} }) {
          nodes { databaseId acf { content { ${root.af('title')} cover { sourceUrl(size: MEDIUM) } } } }
        },
        ${terms}
      }`

      return q
    })

    // ------------------------

    const { data, error, done } = useQuery({ query: query })

    // ------------------------

    const over = ref(false)
    const currentOver = ref(null)

    return { data, over, currentOver }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Creations

  &__breadcrumb
    transition opacity 1s
    &-enter
    &-leave-active
      opacity 0

  &__breadcrumb-nb
    margin-right pxl(60)

  &__breadcrumb-name
    margin-right pxl(20)

  &__cover
    width pxl(360)
    height pxl(490)
    position absolute
    z-index 1
    pointer-events none
    top pxl(-90)
    left pxl(90)
    +portrait()
      display none
    // .Cover
    //   opacity 0
    //   transition opacity 1s

  &__blocks
    display flex
    flex-wrap wrap
    padding-bottom: gutter.component.landscape
    transition opacity 1s
    mq(margin-top 60 30)
    +portrait()
      padding-bottom: gutter.component.portrait
    &-enter
    &-leave-active
      opacity 0
    +landscape()
      &.is-hover
        .Creations__block
          color: colors.black

  &__index
    position relative
    mq(
      top -15 5,
      margin-right 18 10
    )
    +portrait()
      top pxp(15)

  &__title
    position relative
    z-index 0
    display flex
    align-items center
    +portrait()
      align-items flex-start

  &__block
    display flex
    align-items center
    transition color 1s
    mq(
      margin-right 50 0,
      margin-bottom 30 30
    )
    +portrait()
      width 100%
    &:hover
      color: colors.white !important
      z-index 2 !important
      .Creations__title
        z-index 2
      // .Cover
      //   opacity 1

</style>
