<template>
  <div class="Top">

    <div class="Top__logo is-red">
      <svg viewBox="0 0 60.2 50">
        <g transform="translate(77.000000, 47.000000)"><path d="M-17.7-19.9c-0.6,0.7-2.9,4.5-3.9,4.5c-0.3,0-0.6-0.4-0.6-0.7c0-0.4,0.2-1.1,0.4-1.8l3.3-8.7c0.3-0.7,0.3-1.5,0.3-2.1c0-2.2-0.7-3.6-3.2-3.6c-3.5,0-7.1,7.3-8.5,10l-0.1-0.1c0.6-2,1.8-5.1,1.8-7.3c0-1.6-1.3-2.6-2.8-2.6c-4.1,0-6.8,7.3-8.5,10.2l-0.1-0.1c0.6-2.7,1.5-5.6,1.6-7.4c0-1.8-0.8-2.9-2.7-2.8c-2.6,0-5.2,5.2-6.3,7.2l1,0.4c0.6-1,2.5-5.1,4-5.1c0.4,0,0.6,0.3,0.5,0.7c0,0.4-0.2,1.5-0.5,2.7L-46-13h3.9c0.8-3,6.2-16.7,9.3-16.6c0.6,0,0.9,0.4,0.9,1c0,0.2-0.2,0.9-0.4,1.8L-36.4-13h3.9c0.7-2.2,6.2-16.7,9.3-16.6c0.7,0,1.2,0.2,1.2,1c0,0.4-0.1,1-0.3,1.7l-3.5,9.5c-0.4,1-0.5,2-0.5,2.6c0,1.5,0.9,2.4,2.4,2.5c2.9,0,5.5-4.9,7-6.9L-17.7-19.9z"/></g>
      </svg>
    </div>

    <div class="Top__logo is-mix">
      <router-link :to="{name: 'home.' + lang}">
        <svg viewBox="0 0 60.2 50">
          <g transform="translate(77.000000, 47.000000)">
            <polygon points="-47.1,-47 -77,-47 -77,3 -47.1,3 -47.1,-5.9 -67.3,-5.9 -67.3,-17.5 -56,-17.5 -56,-26.5 -67.3,-26.5 -67.3,-38.1 -47.1,-38.1 	"/>
          </g>
        </svg>
      </router-link>
    </div>

    <div class="Top__search" v-if="landscape" @click="$store.commit('app/SET_SEARCH', true)">
      <Icon type="search" />
    </div>
    <div class="Top__lang-switcher" v-if="landscape">
      <LangSwitcher />
    </div>
    <div class="Top__burger" @click="$parent.nav = !$parent.nav">
      <div class="Top__circle" />
      <svg viewBox="0 0 25 19.1">
        <path v-if="$parent.nav" d="M20.8,0l0.7,0.7L13.2,9l9.3,9.3L21.8,19l-9.4-9.2l-9.3,9.3l-0.7-0.7L11.8,9L3.4,0.7L4.1,0l8.3,8.3L20.8,0z"/>
        <path v-if="!$parent.nav" d="M25,15v1H0v-1H25z M25,8.5v1H0v-1H25z M25,2v1H0V2H25z"/>
      </svg>
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import LangSwitcher from '@/components/lang-switcher/LangSwitcher'
import Icon from '@/components/icon/Icon'

export default {
  components: { LangSwitcher, Icon },
  name: 'Top',
  setup(props, { root }) {
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Top
  +portrait()
    width pxp(336)

  &__search
    right pxl(250)
    cursor pointer
    +portrait()
      right pxp(100)

  &__circle
    border: 1px solid colors.white
    position absolute
    mq(
      width 245 150,
      height 245 150,
      border-radius 245 100,
      top -125 -70,
      right -120 -70
    )

  &__search
  &__lang-switcher
  &__burger
  &__logo.is-mix
    mix-blend-mode difference

  &__search
  &__lang-switcher
  &__burger
  &__logo
    position fixed
    top pxl(47)
    z-index 10
    +portrait()
      top: gutter.portrait
  &__search
    top pxl(51)

  &__logo
    left: gutter.landscape
    width pxl(60)
    height pxl(50)
    +portrait()
      width pxp(51)
      height pxp(43)
    svg
      fill white
      path
        fill: colors.red

  &__lang-switcher
    right pxl(305)
    +portrait()
      right pxp(100)

  &__burger
    right gutter.landscape
    width  pxl(65)
    height pxl(65)
    box-sizing border-box
    padding pxl(20)
    margin-right pxl(-15)
    margin-top pxl(-15)
    cursor pointer
    +portrait()
      right: gutter.portrait
      width  pxp(35)
      height pxp(35)
    svg
      fill white

</style>
