<template>
  <div class="BlockFlexible Component">
    <div class="Page__container">
      <Section v-if="data.sections"  :text="data.sections" />
      <div class="Page__container is-small">

        <div class="BlockFlexible__cover" v-if="data.hasCover" :class="{ 'cover-right': data.coverPosition }">
          <div v-if="data.hasCover" class="BlockFlexible__cover-cover">
            <Cover :caption="true" :cover="data.cover" />
          </div>
          <div class="BlockFlexible__cover-text">
            <SplitText type="line" v-if="data.hasTitle">
              <h3 class="BlockFlexible__title" :class="data.titleClass ? data.titleClass : 'h3'">{{ data.title }}</h3>
            </SplitText>
            <Wysiwyg :text="data.text" />
            <Btn :data="data.btn" v-if="data.btn && data.btn.hasButton" />
          </div>
        </div>

        <div class="BlockFlexible__text" v-if="!data.hasCover" :class="{ columns: texts.length > 1, 'has-title': data.hasTitle }">
          <SplitText type="line" v-if="data.hasTitle">
            <h3 class="BlockFlexible__title" :class="data.titleClass ? data.titleClass : 'h3'">{{ data.title }}</h3>
          </SplitText>

          <div class="BlockFlexible__text-columns">
            <div class="BlockFlexible__text-column" v-for="(text, index) in texts">
              <Wysiwyg :text="text" />
              <Btn :data="data.btn" v-if="data.btn && data.btn.hasButton && index == (texts.length - 1)" />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Cover from '@/components/cover/Cover'
import Btn from '@/components/btn/Btn'
import Wysiwyg from '@/components/wysiwyg/Wysiwyg'

export default {
  name: 'BlockFlexible',
  components: { Cover, Btn, Wysiwyg },
  props: {
    data: { default: null }
  },
  setup(props) {
    const texts = props.data.text ? props.data.text.split('&#8212;').map(item => item.trim()) : ['', '']
    return { texts }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.BlockFlexible

  .Btn
    mq(margin-top 65 35)

  &__title
    mq(margin-bottom 65 35)
    &.long
      width pxl(600)

  &__cover
    display flex
    justify-content space-between
    align-items center
    &.cover-right
      flex-direction row-reverse
    +portrait()
      flex-direction column
    &-cover
      position relative
      flex-shrink 0
      flex-grow 0
      mq(
        height 512 423,
        width 405 100%
      )
      +portrait()
        margin-bottom pxp(40)
    &-text
      mq(width 515 100%)

  &__text
    &.has-title
      .BlockFlexible__text-column:nth-child(2)
        +landscape()
          margin-top pxl(-65)
    &.columns
      .BlockFlexible__text-column
        mq(width 450 100%)
    &-columns
      display flex
      justify-content space-between
      flex-wrap wrap
      +portrait()
        flex-direction column
    &-column
      &:nth-child(2)
        margin-top pxl(80)


</style>

