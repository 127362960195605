<template>
  <div class="Filters Component" :class="$route.meta.name">
    <div class="Page__container is-small">
      <template v-if="data">

        <div class="Filters__breadcrumb" :class="{ 'is-hidden': isOpen }">
          <router-link
            :key="index"
            :to="{ params: { category_id: $route.params.category_id == block.databaseId ? '_' : block.databaseId } }"
            v-if="$route.params.category_id == block.databaseId"
            class="Filters__block caption is-active"
            v-for="(block, index) in data.categories.nodes">
            {{ block.acf.title }} <Icon v-if="$route.params.category_id == block.databaseId" type="cross" />
          </router-link>
          <div
            v-if="tagIsInUrl(block.databaseId)"
            class="Filters__block caption is-active"
            @click="setTags(block.databaseId)"
            v-for="block in data.tags.nodes">
            {{ block.acf.title }} <Icon v-if="tagIsInUrl(block.databaseId)" type="cross" />
          </div>
        </div>

        <div class="Filters__toggle button2" :class="{ 'is-active': isOpen }" @click="isOpen = !isOpen">
          {{ ui.filters }} <Icon type="cross" />
        </div>
        <div class="Filters__overflow" ref="overflow">
          <div class="Filters__content">

            <div class="Filters__label caption" v-html="ui.by_categories" />
            <div class="Filters__blocks">
              <router-link
                :key="index"
                :to="{ params: { category_id: $route.params.category_id == block.databaseId ? '_' : block.databaseId } }"
                :class="{ 'is-active': $route.params.category_id == block.databaseId }"
                class="Filters__block caption"
                v-for="(block, index) in data.categories.nodes">
                {{ block.acf.title }} <Icon v-if="$route.params.category_id == block.databaseId" type="cross" />
              </router-link>
            </div>

            <template v-if="$route.meta.name == 'articles'">
              <div class="Filters__label is-tag caption" v-html="ui.by_tags" />
              <div class="Filters__blocks">
                <div
                  :class="{ 'is-active': tagIsInUrl(block.databaseId) }"
                  class="Filters__block caption"
                  @click="setTags(block.databaseId)"
                  v-for="block in data.tags.nodes">
                  {{ block.acf.title }} <Icon v-if="tagIsInUrl(block.databaseId)" type="cross" />
                </div>
              </div>
            </template>

          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon'
import { computed, ref, watch, onMounted } from '@vue/composition-api'
import { useQuery } from 'villus'
import { gsap } from 'gsap'
import Hashids from 'hashids'

export default {
  name: 'Filters',
  components: { Icon },
  setup(props, { root, refs }) {

    const isOpen = ref(false)
    const { data, error, done } = useQuery({
      query: computed(() => `{
        tags { nodes { databaseId acf { ${root.af('title')} } } }
        categories: ccategories { nodes { databaseId acf { ${root.af('title')} } } }
      }`)
    })

    onMounted(() => {
      watch(isOpen, () => {
        setTimeout(() => {
          if(refs.overflow)
            gsap.to(refs.overflow, { height: isOpen.value ? 'auto' : 0 })
        }, 0)
      })
    })

    // ----

    const hashids = new Hashids()

    const setTags = id => {
      const ids = hashids.decode(root.$route.params.tags_ids)

      !tagIsInUrl(id)
      ? ids.push(id)
      : ids.splice(ids.indexOf(id), 1)

      const tags_ids = hashids.encode(ids)

      root.$router.push({ params: {
        category_id: root.$route.params.category_id || '_',
        tags_ids: tags_ids == '' ? null : tags_ids
      }})
    }

    const tagIsInUrl = id => {
      return hashids.decode(root.$route.params.tags_ids).indexOf(id) > -1
    }

    return { data, isOpen, setTags, tagIsInUrl }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Filters
  // +portrait()
  //   margin-bottom pxp(40)

  &.creations
    .Filters__block
      &.is-active
        background: colors.white
        color: colors.black
        svg
          fill: colors.black
  &.articles
    .Filters__block
      &.is-active
        background: colors.black
        color: colors.white
        svg
          fill: colors.white

  .Page__container
    position relative

  &__breadcrumb
    position absolute
    top pxl(8)
    right pxl(150)
    left 0
    display flex
    flex-direction row-reverse
    transition opacity 1s
    &.is-hidden
      opacity 0
      pointer-events none
    +portrait()
      flex-direction row
      right pxp(0)
      left pxp(-20)
      top pxp(60)
      width 100vw
      padding-left pxp(15)
      scrollX()

  &__toggle
    position absolute
    border-width 1px
    border-style solid
    top 0
    right 0
    padding pxl(15) pxl(20)
    cursor pointer
    display flex
    align-items center
    +portrait()
      padding pxp(15) pxp(20)
    &.is-active
      svg
        transform rotate(0deg)
    svg
      mq(
        width 12 12,
        height 12 12,
        margin-left 24 24
      )
      transform rotate(45deg)
      transition transform .5s

  &__content
    border-width 1px
    border-style solid
    padding pxl(50)
    +portrait()
      padding pxp(50) 0

  &__overflow
    overflow hidden
    height 0

  &__label
    mq(margin-bottom 20 20)
    +portrait()
      margin-left: gutter.portrait
    &.is-tag
      mq(margin-top 30 30)

  &__blocks
    display flex
    +portrait()
      scrollX()
      width 100%

  &__block
    border-width 1px
    border-style solid
    display flex
    align-items center
    cursor pointer
    padding pxl(10) pxl(10) pxl(7)
    white-space nowrap
    mq(margin-right 10 10)
    +portrait()
      padding pxp(10) pxp(10) pxp(7)
    svg
      position relative
      mq(
        width 9 9,
        height 9 9,
        margin-left 10 10,
        top -1
      )

</style>
