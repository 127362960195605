<template>
  <transition @leave="leave" @enter="enter" name="Navigation">
    <div class="Navigation">
      <template v-if="data">
        <transition name="Navigation__cover" v-if="landscape">
          <div class="Navigation__cover" :key="cover.sourceUrl" v-if="cover" >
            <Cover :appear="false" :cover="cover" />
            <div class="Navigation__cover-gradient" />
          </div>
        </transition>

        <div class="Navigation__primary">
          <router-link
            class="Navigation__primary-link"
            @click.native="$parent.nav = false"
            @mouseenter.native="cover = covers[nav.page.acf.settings.handle]; circles[key] = true"
            @mouseleave.native="cover = covers[$route.meta.name]; circles[key] = false"
            :to="{ name: nav.page.acf.settings.handle + '.' + lang }"
            v-for="(nav, key) in data.general.acf.menu.primary"
            :key="key">
            <span class="Navigation__circle caption">
              <RedCircle :active="circles[key]" />
              0{{key + 1}}
            </span>
            <SplitText ref="split" renderMargin="-20% 0% -20% 0%" v-if="landscape">
              <h1 class="h1-alt">{{ nav.label }}</h1>
            </SplitText>
            <h1 v-if="portrait" class="h1-alt">{{ nav.label }}</h1>
          </router-link>
        </div>

        <div class="Navigation__secondary">
          <div ref="line" class="Navigation__line" />
          <div class="Navigation__secondary-inner" ref="secondary">
            <router-link
              @click.native="$parent.nav = false"
              @mouseenter.native="cover = covers[nav.page.acf.settings.handle]"
              @mouseleave.native="cover = covers[$route.meta.name]"
              class="Navigation__secondary-link"
              :to="{ name: nav.page.acf.settings.handle + '.' + lang }"
              v-for="(nav, key) in data.general.acf.menu.secondary"
              :key="key">
              <div class="button1">{{ nav.label }}</div>
            </router-link>
            <div class="Navigation__social">
              <Socials />
              <LangSwitcher v-if="portrait" />
            </div>
          </div>
        </div>

      </template>
    </div>
  </transition>
</template>

<script>
import { computed, watch, ref } from '@vue/composition-api'
import { useQuery } from 'villus'
import Socials from '@/components/socials/Socials'
import { gsap } from 'gsap'
import Cover from '@/components/cover/Cover'
import LangSwitcher from '@/components/lang-switcher/LangSwitcher'
import RedCircle from '@/components/red-circle/RedCircle'

export default {
  name: 'Navigation',
  components: { Socials, Cover, LangSwitcher, RedCircle },
  setup(props, { root, refs }) {
    const types = ['creations', 'ex_machina', 'robert_lepage', 'team', 'contact']
    const pages = _.map(types, type => ` ${type}: pages(where: {metaQuery: {metaArray: {key: "settings_handle", value: "${type}"}}}) {
        nodes { acf_${type} { intro { cover { sourceUrl (size: LARGE) } } } }
      }`).join('')

    const { data, error, done } = useQuery({
      query: computed(() => `{
        general {
          acf {
            menu {
              primary { ${root.af('label')} page { ... on Page { acf { settings { handle } } } } }
              secondary { ${root.af('label')} page { ... on Page { acf { settings { handle } } } } }
            }
          }
        }
        ${pages}
        articles(first: 1) { nodes { acf { intro: content { cover { sourceUrl (size: LARGE)}}} } }
      }`)
    })

    const covers = ref({})
    const cover = ref(null)
    const circles = ref([])

    watch(data, () => {
      if(!data.value) return
      _.each(types, type => root.$set(covers.value, type, data.value[type].nodes[0]['acf_' + type].intro.cover))
      root.$set(covers.value, 'articles', data.value.articles.nodes[0].acf.intro.cover)

      _.each(data.value.general.acf.menu.primary, n => circles.value.push(false))
    })

    const enter = (el, done) => {
      gsap.set(refs.secondary, { y: '100%', alpha: 0 })
      gsap.set(refs.line, { scaleX: 0 })
      gsap.to(refs.line, { delay: .5, scaleX: 1 })
      gsap.to(refs.secondary, { delay: 1, y: '0%', alpha: 1, onComplete: done })
    }

    const leave = (el, done) => {
      _.each(refs.split, s => s.reverse())
      setTimeout(() => done, 1000)
    }

    return { data, enter, leave, cover, covers, circles }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Navigation
  position fixed
  top 0
  left 0
  height 100%
  width 100vw
  padding: gutter.landscape
  box-sizing border-box
  display flex
  flex-direction column
  justify-content space-between
  z-index 5
  background: colors.grey.dark
  transition transform 1.5s cubic-bezier(0.16, 1, 0.3, 1)
  &-enter
  &-leave-active
    transform translateY(-100%)

  .h1-alt
    +portrait()
      font-size pxp(43)
      line-height pxp(60)

  &__circle
    position relative
    width pxl(36)
    height pxl(36)
    position relative
    justify-content center
    align-items center
    display flex

  &__cover
    position absolute
    right 0
    top 0
    bottom 0
    width pxl(703)
    overflow hidden
    transform translate3d(0, 0, 0)
    transition opacity 1s, transform 1s
    &-enter
      transform scale(1.1)
      opacity 0
    &-leave-active
      opacity 0
      transform scale(.9)
    &-gradient
      position absolute
      width 100%
      height 100%
      top 0
      left 0
      background linear-gradient(to left, rgba(colors.grey.dark, 0), rgba(colors.grey.dark, 1))
      .Cover__bg
        background-position left center

  &__primary
    padding pxl(50) 0
    flex 1
    display flex
    flex-direction column
    justify-content center
    transform translate3d(0, 0, 0)
    +portrait()
      padding pxp(100) 0 pxp(50)
    &-link
      display flex
      align-items center
      margin-bottom pxl(10)
      &:hover
        .Navigation__circle
          &:before
            opacity 1
            transform translate(-50%, -50%) scale(.9)
      span
        margin-right pxl(30)
        position relative
        top pxl(5)
        // display block

  &__line
    position absolute
    top 0
    left 0
    right 0
    height 1px
    background: rgba(colors.white, .3)
    transform-origin left top
    transform scaleX(0)

  &__secondary
    position relative
    padding-top pxl(50)
    &-inner
      display flex
      align-items center
      right auto
      width auto
      +portrait()
        flex-direction column
        align-items flex-start
        padding-top pxp(40)
    &-link
      margin-right pxl(50)
      position relative
      +landscape()
        &:after
          content ''
          position absolute
          bottom pxl(-5)
          left 0
          right 0
          height 1px
          background: colors.red
          transform scaleX(0)
          transition transform .5s
        &:hover
          &:after
            transform scaleX(1)
      +portrait()
        margin-bottom: gutter.portrait

  &__social
    +portrait()
      display flex
      justify-content space-between
      align-items center
      width 100%
      margin-top pxp(10)


</style>
