<template>
  <Viewport>
    <comp
      :is="btn.is"
      :to="btn.router"
      :href="btn.url"
      :target="btn.target"
      class="Btn"
      :class="[type, { 'has-anim': appear }]">
      {{ btn.label }}
      <Icon v-if="icon" :type="data.type == 'external' ? 'external' : 'arrow'" />
    </comp>
  </Viewport>
</template>

<script>
import Icon from '@/components/icon/Icon'

export default {
  name: 'Btn',
  components: { Icon },
  props: {
    data: { default: null },
    type: { default: 'button2' },
    icon: { default: true },
    appear: { default: true }
  },
  setup(props, { root }) {
    let router = null
    let btn = {}
    const { data } = props

    if(data.type != 'external' && data.type != 'file') {
      router = {
        name: (data.type == 'page' ? data.page.acf.settings.handle : data.type) + '.' + root.lang,
        params: data.type == 'page' ? {} : { id: data.page.databaseId, slug: root.kebab(data.page.acf.content.title) }
      }
    }

    if(data.url && /\S+@\S+\.\S+/.test(data.url))
      data.url = 'mailto:' + data.url

    btn = {
      is: data.type == 'external' || data.type == 'file' ? 'a' : 'router-link',
      target: data.type == 'external' || data.type == 'file' ? '_blank' : null,
      router: router,
      url: data.type == 'file' && data.file ? data.file.mediaItemUrl : data.url,
      label: data.label
    }

    return { btn }
  }
}
</script>


<style lang="stylus">
@import '~@/config/Settings'

.Btn
  display flex
  cursor pointer
  align-items center
  +landscape()
    &.is-hover
    &:hover
      svg
        transform translateX(pxl(10))
  &.has-anim
    opacity 0
    transform translateX(pxl(20))
    transition 1s transform, opacity 1s
  &.has-anim.is-in-viewport
    transform translate(0px)
    opacity 1

  svg

    display block
    transition transform .5s
    mq(margin-left 10 10)

</style>
