<template>
  <Parallax :y="-100">
    <div class="ScrollCta">
      <div class="ScrollCta__label rotate caption" v-html="ui.scroll" />
      <div class="ScrollCta__line" />
    </div>
  </Parallax>
</template>

<script>
export default {
  name: 'ScrollCta'
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.ScrollCta
  position absolute
  top pxl(565)
  left: pxl(69)
  mix-blend-mode difference
  pointer-events none
  display flex
  flex-direction column
  align-items center
  +portrait()
    left: gutter.portrait
    top pxp(300)

  &__label
    writing-mode vertical-rl
    transform rotate(-180deg)

  &__line
    width 1px
    height 60vh
    background: colors.white
    mq(margin-top 40 20)

</style>
