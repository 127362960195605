<template>
  <Viewport :rootMargin="renderMargin" @enter="play" >
    <Viewport rootMargin="10% 0% 10% 0%" @enter="reverse" >
      <slot  />
    </Viewport>
  </Viewport>
</template>

<script>
import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"
import { onMounted, onUnmounted } from '@vue/composition-api'
gsap.registerPlugin(SplitText)

export default {
  name: 'SplitText',
  props: {
    type: { default: 'char' },
    from: { default: null },
    renderMargin: { default: '-30% 0% -30% 0%' }
  },
  setup(props, { root, slots }) {
    let split = null
    const tl = gsap.timeline({ paused: true })
    const play = () => setTimeout(() => tl.play())
    const reverse = () => tl.reverse()

    onMounted(() => {
      let className = `SplitText__lines `
      const el = slots.default()[0].elm

      _.each(['h1', 'highlight', 'h1-alt', 'h2', 'h2-alt', 'h3'], t => {
        if(el.classList.contains(t))
          className += t
      })

      if(props.type == 'char') {
        split = new SplitText(el, { type: 'lines, chars', linesClass: className})
        tl.from(split.chars, { y: '100%', duration: 1.4, ease: "expo.out", stagger: { each: .04, from: props.from } })
      }

      if(props.type == 'line') {
        split = new SplitText(el, { type: 'lines', linesClass: className})
        new SplitText(el, { type: 'lines, words', linesClass: className})
        if(split.lines.length > 0)
          tl.from(split.lines, { y: '110%', duration: 1.4, ease: "expo.out", stagger: { each: .1 } })
      }
    })

    return { play, reverse }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.SplitText

  &__lines
    overflow hidden
    &.highlight
      > div
        top pxl(-18)
        +portrait()
          top pxp(-7)
    &.h1-alt
      > div
        top pxl(-9)
        +portrait()
          top pxp(-4)
    &.h2-alt
      > div
        top pxl(-2)
        +portrait()
          top pxp(-1)
    &.h2
      > div
        top pxl(-2)
        +portrait()
          top pxp(-2)
    &.h3
      > div
        top pxl(-2)
        +portrait()
          top pxp(-1)

</style>

