<template>
  <div class="Publications Component">
    <div class="Page__container Publications__content">
      <Section :text="ui.publications" />

      <div class="Page__container is-small Publications__blocks">
        <a
          @mouseenter="enter(index)"
          @mouseleave="leave(index)"
          :href="publication.url"
          target="_blank"
          class="Publications__block"
          v-for="(publication, index) in publications">
          <SplitText type="line">
            <h4 class="h4">{{ publication.title }}</h4>
          </SplitText>
          <Btn
            ref="btn"
            :data="{
              type: 'external',
              url: publication.url,
              label: ui.show
            }"
          />
        </a>
      </div>

    </div>
  </div>
</template>

<script>
import Btn from '@/components/btn/Btn'

export default {
  name: 'Publications',
  props: ['publications'],
  components: { Btn },
  setup(props, { root, refs }) {
    const enter = index => refs.btn[index].$el.classList.add('is-hover')
    const leave = index => refs.btn[index].$el.classList.remove('is-hover')
    return { enter, leave }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Publications

  .Btn
    mq(margin-top 30 20)

  .Section
    +portrait()
      margin-left: gutter.portrait

  &__content
    +portrait()
      margin 0
      width 100%

  &__blocks
    display flex
    mq(margin-left 220 0)
    +portrait()
      margin pxp(50) 0 0 0
      width 100% !important
      scrollX()

  &__block
    justify-content space-between
    display flex
    flex-direction column
    flex-shrink 0
    mq(
      width 295 246,
      margin-right 35 20
    )
    &:nth-child(3n)
      margin-right 0

</style>

