<template>
  <div class="Cta Component">
    <div class="Page__container">
      <!--Section v-if="data.hasLabel" :text="data.label" /-->
      <div class="Page__container is-small" v-if="data">
        <div class="Cta__sections sections" v-if="data.hasLabel">{{ data.label }}</div>
        <Parallax>
          <div class="Cta__circle">
            <div class="Cta__circle-border" />
          </div>
        </Parallax>
        <SplitText type="line">
          <h1 class="Cta__title h1-alt" v-html="data.title" />
        </SplitText>
        <Btn :data="data.btn" />
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/btn/Btn'
export default {
  name: 'Cta',
  props: {
    data: { default: null },
  },
  components: { Btn },
  setup(props, { root }) {}
}
</script>

<style lang="stylus">
@import '~@/config/Settings'
.Cta

  .Page__container.is-small
    mq(padding-top 180 120)

  &__sections
    mq(margin-bottom 70 40)

  &__circle
    position absolute
    right 0
    top 0
    box-sizing border-box
    &.is-in-viewport
      .Cta__circle-border
        transform scale(1)
        opacity 1
    mq(
      width 516 313,
      height 516 313,
      right 0 -150,
      top 0 0
    )
    &-border
      position absolute
      width 100%
      height 100%
      border: 1px solid colors.red
      transition opacity 1s, transform 1s
      transform scale(.5)
      transition-delay .2s
      border-radius 100%
      opacity 0

  &__title
    mq(margin-bottom 45 40)
    +landscape()
      min-height pxl(150)

</style>

