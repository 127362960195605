<template>
  <Viewport :rootMargin="`${Math.abs(y)}px 0px`" @enter="enter" @leave="leave">
    <slot />
  </Viewport>
</template>

<script>
import { gsap } from 'gsap'
import { computed, watch, onMounted, onUnmounted } from '@vue/composition-api'
import Viewport from '@/components/viewport/Viewport'

export default {
  name: 'Parallax',
  abstract: true,
  props: {
    y: { default: 50 },
    force3D: { default: true }
  },
  setup(props, { refs, root, slots, emit }) {
    const scrollY = computed(() => root.$store.getters['app/scroll'].y)
    const normalize = (val, max, min) => (val - min) / (max - min)
    let el = null
    let unwatch = () => {}
    const enter = elm => {
      el = elm
      unwatch = watch(scrollY, render)
    }
    const leave = el => {
      unwatch()
      el.style.transform = 'none'
    }
    const render = () => {
      const shape = el.getBoundingClientRect()
      const n = normalize(shape.y, window.innerHeight + props.y, -shape.height - props.y) - .5
      const y = parseInt(n * (props.y * 2))

      props.force3D
      ? el.style.transform = `translate3d(0px, ${y}px, 0px)`
      : el.style.transform = `translate(0px, ${y}px)`
    }

    onUnmounted(() =>  unwatch())

    return { enter, leave }
  }
}
</script>
