<template>
  <div class="Icon" :class="['is-' + type]">
    <div v-if="type == 'circleArrow'">
      <svg viewBox="0 0 51.4 36">
        <path class="is-circle" d="M36,17.4C35.7,7.8,27.8,0,18,0C8.1,0,0,8.1,0,18s8.1,18,18,18c9.8,0,17.8-7.8,18-17.6 M35,18.5C34.8,27.6,27.3,35,18,35 C8.6,35,1,27.4,1,18S8.6,1,18,1c9.2,0,16.7,7.3,17,16.5 M700.2,381.1v2.5l6.9-3l-6.9-2.9v2.4l-27.5,0v1L700.2,381.1z"/>
      </svg>
      <svg viewBox="0 0 51.4 36">
        <path class="is-line" d="M44.5,18.4v2.5l6.9-3L44.5,15v2.4l-27.5,0v1L44.5,18.4z"/>
      </svg>
    </div>

    <svg viewBox="0 0 35.4 5.9" v-if="type == 'arrow'">
      <path d="M28.5,0l6.9,2.9l-6.9,2.9V3.4L0,3.4v-1l28.5,0V0z"/>
    </svg>

    <svg viewBox="0 0 35.4 9" v-if="type == 'external'">
      <polygon points="35.4,4.5 28.5,1.6 28.5,4 1,4 1,0 0,0 0,9 1,9 1,5 28.5,5 28.5,7.4 "/>
    </svg>

    <svg viewBox="0 0 11.8 11.2" v-if="type == 'cross'">
      <path d="M10.5,0l0.7,0.7L6.6,5.3l5.2,5.2l-0.7,0.7L5.9,6l-5.2,5.2L0,10.5l5.2-5.2L0.6,0.7L1.3,0 l4.6,4.6L10.5,0z"/>
    </svg>

    <svg viewBox="0 0 24.5 24.5" v-if="type == 'search'">
      <path d="M0,10.2C0,4.6,4.6,0,10.3,0s10.3,4.6,10.3,10.2c0,2.8-1.1,5.3-2.9,7.1l6.7,6.4l-0.7,0.7L17,18.1c-1.8,1.5-4.1,2.4-6.7,2.4C4.6,20.5,0,15.9,0,10.2z M1,10.2c0,5.1,4.2,9.2,9.3,9.2s9.3-4.1,9.3-9.2S15.5,1,10.3,1S1,5.1,1,10.2z"/>
    </svg>

    <svg viewBox="0 0 5.9 17.2" v-if="type == 'arrowDown'">
      <path d="M5.9,10.2l-2.9,6.9L0,10.2l2.5,0L2.5,0h1l0,10.2L5.9,10.2z"/>
    </svg>

    <svg v-if="type == 'instagram'" viewBox="0 0 13.5 13.5">
      <g>
        <circle fill="none" cx="10.4" cy="3.1" r="0.8"/>
        <path  fill="none" class="st0" d="M6.7,3.3c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S8.7,3.3,6.7,3.3z M6.7,9
          C5.5,9,4.5,8,4.5,6.7s1-2.2,2.2-2.2S9,5.5,9,6.7S8,9,6.7,9z"/>
        <circle cx="6.7" cy="6.7" r="2.2"/>
        <path d="M13.5,4c0-0.7-0.1-1.2-0.3-1.6c-0.2-0.4-0.4-0.8-0.8-1.2c-0.4-0.4-0.8-0.6-1.2-0.8C10.7,0.2,10.3,0.1,9.5,0
          C8.9,0,8.7,0,7.6,0H5.9C4.8,0,4.5,0,4,0c-0.7,0-1.2,0.1-1.6,0.3C1.9,0.5,1.5,0.8,1.1,1.1C0.8,1.5,0.5,1.9,0.4,2.3
          C0.2,2.8,0.1,3.2,0,4c0,0.6,0,0.8,0,1.9v1.8c0,1.1,0,1.3,0,1.9c0,0.7,0.1,1.2,0.3,1.6c0.2,0.4,0.4,0.8,0.8,1.2
          c0.4,0.4,0.8,0.6,1.2,0.8c0.4,0.2,0.9,0.3,1.6,0.3c0.7,0,0.9,0,2.8,0s2.1,0,2.8,0c0.7,0,1.2-0.1,1.6-0.3c0.4-0.2,0.8-0.4,1.2-0.8
          c0.4-0.4,0.6-0.8,0.8-1.2c0.2-0.4,0.3-0.9,0.3-1.6c0-0.7,0-0.9,0-2.8S13.5,4.7,13.5,4z M6.7,10.2c-1.9,0-3.5-1.6-3.5-3.5
          s1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5S8.7,10.2,6.7,10.2z M10.4,4C9.9,4,9.5,3.6,9.5,3.1c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8
          C11.2,3.6,10.8,4,10.4,4z"/>
      </g>
    </svg>

    <svg v-if="type == 'facebook'" viewBox="0 0 14 14">
      <path class="st0" d="M8,3.1c0-0.5,0.3-0.7,0.6-0.7h1.5V0L8,0C5.7,0,5.2,1.8,5.2,2.9v1.6H3.9V7h1.3v7H8V7h1.9l0.2-2.5H8V3.1z"/>
    </svg>

    <svg v-if="type == 'twitter'" viewBox="0 0 14 14">
      <path d="M13.6,1.3c-0.6,0.3-1.2,0.6-1.8,0.7c-0.5-0.6-1.3-0.9-2.1-0.9c-1.6,0-2.9,1.3-2.9,3c0,0.2,0,0.5,0.1,0.7 C4.5,4.6,2.4,3.4,1,1.6c-0.2,0.4-0.4,1-0.4,1.5c0,1,0.5,2,1.3,2.5c-0.5,0-0.9-0.1-1.3-0.4c0,0,0,0,0,0c0,1.4,1,2.7,2.3,2.9 C2.6,8.3,2.4,8.3,2.1,8.3c-0.2,0-0.4,0-0.5-0.1c0.4,1.2,1.4,2.1,2.7,2.1c-1,0.8-2.2,1.3-3.6,1.3c-0.2,0-0.5,0-0.7,0 c1.3,0.8,2.8,1.3,4.4,1.3c5.3,0,8.2-4.6,8.2-8.5c0-0.1,0-0.3,0-0.4c0.6-0.4,1-0.9,1.4-1.5c-0.5,0.2-1.1,0.4-1.6,0.5 C12.9,2.6,13.4,2,13.6,1.3z"/>
    </svg>

    <svg v-if="type == 'youtube'" viewBox="0 0 512 512">
      <path d="M501.303,132.765c-5.887,-22.03 -23.235,-39.377 -45.265,-45.265c-39.932,-10.7 -200.038,-10.7 -200.038,-10.7c0,0 -160.107,0 -200.039,10.7c-22.026,5.888 -39.377,23.235 -45.264,45.265c-10.697,39.928 -10.697,123.238 -10.697,123.238c0,0 0,83.308 10.697,123.232c5.887,22.03 23.238,39.382 45.264,45.269c39.932,10.696 200.039,10.696 200.039,10.696c0,0 160.106,0 200.038,-10.696c22.03,-5.887 39.378,-23.239 45.265,-45.269c10.696,-39.924 10.696,-123.232 10.696,-123.232c0,0 0,-83.31 -10.696,-123.238Zm-296.506,200.039l0,-153.603l133.019,76.802l-133.019,76.801Z" />
    </svg>

    <svg v-if="type == 'linkedin'" viewBox="0 0 12 12">
      <path d="M0,12h3V4H0V12z M1.5,0C0.6,0,0,0.6,0,1.5C0,2.3,0.6,3,1.5,3h0C2.4,3,3,2.3,3,1.5 C3,0.6,2.4,0,1.5,0L1.5,0z M12,7.5V12H9.4V7.8c0-1.1-0.4-1.8-1.3-1.8c-0.7,0-1.2,0.5-1.3,1C6.6,7.2,6.6,7.4,6.6,7.6V12H4 c0,0,0-7.1,0-7.8h2.6v1.1c0,0,0,0,0,0h0v0C7,4.8,7.6,4,9,4C10.7,4,12,5.1,12,7.5L12,7.5z"/>
    </svg>

    <svg v-if="type == 'email'" viewBox="0 0 14 14">
      <path d="M1.5,1.5c-0.4,0-0.7,0.1-1,0.4L7,6.4l6.5-4.5c-0.3-0.2-0.6-0.4-1-0.4C12.5,1.5,1.5,1.5,1.5,1.5z M14,2.8L7,7.6L0,2.8 C0,2.9,0,2.9,0,3v8c0,0.8,0.7,1.5,1.5,1.5h11c0.8,0,1.5-0.7,1.5-1.5V3C14,2.9,14,2.8,14,2.8z"/>
    </svg>

  </div>
</template>

<script>
export default {
  name: 'Icons',
  props: ['type']
}
</script>

<style lang="stylus">
@import '~@/config/Settings'
.Icon

  &.is-search
    mq(
      width 25 25,
      height 25 25
    )

  &.is-arrow
    mq(
      width 35 35,
      height 6 6
    )

  &.is-external
    mq(
      width 35 35,
      height 6 6
    )

  &.is-circleArrow
    position relative
    mq(
      width 50 50,
      height 36 36
    )
    svg
      transition transform .5s
      position absolute
      top 0
      left 0
      width 100%
      height 100%
    &:hover
      svg
        &:first-child
          transform scale(1.1)
        &:last-child
          transform translateX(pxl(10))

  &.is-arrowDown
    mq(
      width 6 6,
      height 18 18
    )

  svg
    display block

</style>
