import Vue from 'vue'
import Settings from '@/config/Settings'

const scroll = { y: 0, x: 0 }
const windowSizes = { width: window.innerWidth, height: window.innerHeight }

window.addEventListener('scroll', () => {
  scroll.y = document.documentElement.scrollTop
  scroll.x = document.documentElement.scrollLeft
})

window.addEventListener('resize', () => {
  windowSizes.width = window.innerWidth
  windowSizes.height = window.innerHeight
})

const route = {
  name: null,
  meta: {
    lang: null,
    name: null
  }
}

export default {
  namespaced: true,
  state: {
    scroll: scroll,
    windowSizes: windowSizes,
    lang: null,
    subscribed: false,
    search: false,
    cursor: false,
    ui: {},
    route: {
      from: route,
      to: route
    }
  },
  mutations: {
    'SET_CURSOR' (state, obj) {
      state.cursor = obj
    },
    'SET_SEARCH' (state, bool) {
      state.search = bool
    },
    'SET_SUBSCRIBE' (state) {
      state.subscribed = true
    },
    'SET_UI' (state, data) {
      state.ui = data
    },
    'SET_FROM_ROUTE' (state, from) {
      state.route.from = from
    },
    'SET_TO_ROUTE' (state, to) {
      state.route.to = to
    },
    'LANG_REFRESH' (state) {
      const l = state.lang
      state.lang = 'none'
      state.lang = l
    },
    'LANG_CHANGED' (state, lang) {
      state.lang = lang
    },
  },
  getters: {
    lang: state => state.lang,
    route: state => state.route,
    scroll: state => state.scroll,
    search: state => state.search,
    cursor: state => state.cursor,
    subscribed: state => state.subscribed,
    windowSizes: state => state.windowSizes,
    ui: state => state.ui
  }
}
